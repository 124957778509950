<template>
  <b-container class="mt-3 admin-page">
    <NavBar />
    <b-row>
      <b-col>
        <b-button
          :disabled="this.queBtnStatus > 0"
          href="#"
          variant="outline-secondary"
          class="btn-antrian antrian"
          block
          @click="nextQueue"
        >
          Antrian Selanjutnya
        </b-button>
      </b-col>
      <b-col>
        <b-button disabled class="antrian-box mb-2">
          <p>Nomor Antrian Sekarang</p>
          <h1>{{ queNum }}</h1>
          <span>{{queCategory}}</span>
        </b-button>
        <b-button disabled class="antrian-box">
          <h1>{{ countQueNum }}</h1>
          <p>Jumlah Antrian Menunggu</p>
        </b-button>
      </b-col>
    </b-row>
    <b-row>      
      <b-button
      href="#"
      variant="outline-secondary"
      class="antrian-recall"
      block
      @click.prevent="handleRecall"
      v-if="this.queNum"
      >
      Panggil Ulang
      </b-button>  
    </b-row> 

    <!-- no specialist code begin -->
    <div v-if="this.special == 'false'">       
      <b-row class="mt-3" v-if="this.skipStatus == 0">
          <b-col>
            <v-select
                :options="options"
                :placeholder="placeholder"
                v-model="checked"
                value-field="item"
                text-field="label"
                @input="onChecked"
                @click="skipQue"
            ></v-select>
          </b-col>
      </b-row>
    </div>

    <!-- no specialist code end -->

    <b-row class="mt-3">
      <b-col :hidden = "catdok == 0">
        <b-button pill block v-b-modal.modalCatatan @click="note"
          >Buka Catatan</b-button
        >
      </b-col>
    </b-row>
    
    <b-row class="mt-3">
      <b-col>
        <b-button
          v-show="btnNext"
          pill
          block
          variant="outline-secondary"
          @click="finish(10)"
        >
          Lanjut Treatment
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button
          v-show="btnFinish"
          pill
          block
          variant="outline-secondary"
          @click="finish(20)"
        >
          Selesai Transaksi
        </b-button>
      </b-col>
    </b-row>

    <!-- Modal checklis kasir  -->

    <b-modal id="modalCatatan" centered hide-header hide-footer>
      <form>
        <b-row>
          <b-col>
            <p>Catatan: </p>
          </b-col>
          <b-col class="text-right mr-1">
            <label class="form-checkbox">
              <input type="checkbox" v-model="selectAll" @click="allSelectItem" />
              All 
            </label>
          </b-col>
        </b-row>
        <b-row class="mt-1 mb-3">
          <b-col>
              <b-list-group>
                <b-list-group-item 
                    v-for="(i, index) in items"
                    :key="index"
                    class="d-flex justify-content-between align-items-end"
                    >{{ index+1 }} - {{ i.catatan }}
                    <b-badge variant="transparent" pill>       
                      <input type="checkbox" :value="i.id" v-model="selected" @change="select"/>
                    </b-badge>
                </b-list-group-item>
                <b-list-group :hidden="this.items.length != 0" class="text-center">
                  <p class="text-secondary">Belum ada produk</p>
                </b-list-group>
              </b-list-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-button variant="outline-secondary" @click="openNewNote" v-if="this.special == 'false'">Tambah Catatan</b-button>
            <b-button align-item="end" style="float: right" @click="save">Submit</b-button>
        </b-col>
      </b-row>
      </form>
    </b-modal>
    <!-- akhir modal checklist kasir -->
    
    <!-- akhir modal tambah kasir -->  
    <b-modal id="modalTambahCatatan" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <form>
        <b-row>
          <b-col>
            <p>Tambah Catatan: </p>
          </b-col>
        </b-row>
        <b-row class="mt-1 mb-3">
          <b-col>
              <b-list-group>
                <b-list-group-item
                    v-for="(i, index) in items"
                    :key="index"
                    class="d-flex justify-content-between align-items-end"
                    >{{ i.catatan }}
                </b-list-group-item>
              </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group>
                <b-list-group-item
                  v-for="(attr, i) in attributes"
                  :key="i"
                  class="d-flex justify-content-between align-items-end"
                  >{{ attr }}
                  <b-badge variant="transparent" pill>                      
                    <b-icon
                    icon="trash"
                    scale="1.5"
                    variant="danger"
                    @click="delNote(i)"
                    ></b-icon>          
                  </b-badge>
                </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-card class="mb-3 p-0">          
          <b-row>
            <b-col cols="9">       
                <div>
                  <DoctorLOV :id="'CatatanModal'" :title="'Catatan Baru'" :code="'GET ATTRIBUTE'" :valueKeyName="['name']" :keyName="'code'" :val="{key: this.options.attribute_id, value: this.options.attribute_name}" @selectedVal="getResult" ref="CatatanModal"/>
                </div>
            </b-col>
            <b-col cols="3">
                <b-button variant="outline-success" @click="addNote()">Tambah</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-button variant="transparent" @click="backNote">Kembali</b-button>
        <b-button align-item="end" style="float: right" @click="saveNewNote">Simpan</b-button>
      </form>
    </b-modal>
    <!-- akhir modal tambah kasir -->
    
    <PhoneModal ref="ModalPhone" 
        :titleProps="this.titleModal" 
        :numberProps="this.queNum" 
        :codeProps="this.actionCode" 
        @submitFinish="submitFinish"/>

  </b-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from "../../services/UserServices.js";
import socket from "../../socket";
import DoctorLOV from '@/components/DoctorLOV.vue';
import PhoneModal from "../../components/PhoneModal.vue";

export default {
  name: "Kasir",
  components: {
    NavBar, DoctorLOV, PhoneModal 
  },
  data() {
    return {
      items: [],
      selected: [],
      allSelected: [],
      selectAll: false,
      queNum: "",
      queCategory:null,
      queCode:null,
      countQueNum: null,
      token: "",
      catdok: 0,
      btnNext: 0,
      btnFinish: 0,
      attrLength: 0,
      titleModal:"",
      textConfirm: '',
      valueCode: 0,
      alertMessage: '',
      skipStatus: 0,

      // no specialist
      checked: null,
      options: [],
      placeholder: "",
      special: true,
      noteStatus: 0,
      queBtnStatus: 0,
      attributes: [],
      newNote : {},
      actionCode: 0

    };
  },

  beforeMount() {
    this.token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");
    this.special = sessionStorage.getItem('special');
    console.log('Special? ',this.special)
    this.placeholder = 'Pilih antrian terlewat';
    if (this.token == null) {
      return this.$router.push("/login");
    } else if (user != "CSR") {
      return this.$router.push("/home");
    } else {
      this.btnNext = 0;
      this.btnFinish = 0;
      this.catdok = 0;
      this.getCountQueNum();
      this.skipStatus = 0;
      this.skipQue();
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token};
    socket.connect();

    socket.on("connect", () => {
      console.log("Handshake success kasir")
    });

    socket.on("share push queue", (data) => {
      this.countQueNum = data.count
    });
  },
  methods: {
    allSelectItem() {
      this.selectAll = !this.selectAll
      if (this.selectAll) {
        this.selected = this.allSelected
      }else{
        this.selected = []
      }
    },
    reload(){      
        this.getCountQueNum();
        this.catdok = 0
        this.btnNext = 0
        this.btnFinish = 0;
        this.alertMessage = '';
        this.queCategory = null;
        this.queNum = null;
        this.selected = []
        this.selectAll = false
        this.allSelected = []
        this.skipStatus = 0;
        this.checked = null;
        this.queBtnStatus = 0;
        this.actionCode = 0;
        this.skipQue();
    },
    select(){
      console.log("Length allSelected: ", this.allSelected.length);
      console.log("Length selected: ", this.selected.length);
      if(this.selected.length != this.allSelected.length){
        this.selectAll = false;
      }else if(this.selected.length == 0){
        this.selectAll = false;
      }else{
        this.selectAll = true;
      }
    },
    nextQueue: function () {
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token };
        callAPI(request, 'CASHIER GET QUEUE NUMBER').then((response) => {
          if (response.outError == 0) {
            console.log('res que: ',response);
            this.queNum = response.outOrderNumber;
            this.queCategory = response.outCategoryName;
            this.queCode = response.outCategoryCode;
            this.catdok = 1;
            this.btnNext = 0;
            this.btnFinish = 0;
            this.skipStatus = 1;
          } else {
            this.$alert('Belum ada antrian', 'Gagal', 'error');
          }          
          this.skipQue();
        });
      });
    },
    // ini comment
    getCountQueNum: function(){
      const request = {
        token: this.token
      }
      callAPI(request, 'CASHIER GET COUNT QUEUE NUMBER').then((response) => {
        if(response.outError != 0){
          this.$alert(response.outMessage);
        }else{
          this.countQueNum = response.outCount;
        }
      })
    },
    skipQue(){
        const request = {
            token: this.token,
            searchValue: ''
        };
        callAPI(request, 'CASHIER LOV CUSTOM QUEUE').then((response) => {
            let optionVal = []
            if(response.outData != null){
              console.log("isi yg terlewat: ", response.outData)
                for(let x = 0; x < response.outData.length; x++){
                    let dataSkip = {
                        label: response.outData[x].order_number +' - '+response.outData[x].category_name,
                        value: response.outData[x]
                    }
                    optionVal.push(dataSkip)
                }
                this.options = optionVal
                console.log('option: ', this.options)
            }
        });
    },
    onChecked(){
        console.log('nomor terlewat: ',this.checked)
        this.items = [];
        if(this.checked){           
          this.queNum = this.checked.value.order_number;
          this.queCategory = this.checked.value.category_name;
          this.queCode = this.checked.value.category_code;
          this.catdok = 1;
          this.queBtnStatus = 1;
          this.btnNext = 0;        
          this.btnFinish = 0;
        }else{
          this.catdok = 0;
          this.queBtnStatus = 0;
          this.btnNext = 0;
          this.btnFinish = 0;
          this.selectAll = false;
        }
    },
    note: function () {
      this.allSelected = []
      this.items = []
      const request = {
        token: this.token,
        orderNumber: this.queNum,
      };
      callAPI(request, 'CASHIER GET ATTRIBUTE').then((response) => {
        if (response.outError == 0) {
          this.items = [];
          // this.selected = [];
          this.attrLength = response.outData.length
          for (let i = 0; i < this.attrLength; i++) {
            this.items.push({
              id: response.outData[i].attribute_id,
              catatan: response.outData[i].attribute,
              is_remove: 1
            });
            this.allSelected.push(
              response.outData[i].attribute_id,
            )
            console.log("All Selected: ", this.allSelected);
          }
        }
      });
    },    
    openNewNote(){      
      this.attributes = []
      this.$bvModal.hide("modalCatatan");
      this.$bvModal.show("modalTambahCatatan");
    },
    saveNewNote(){      
      if(this.attributes.length > 0){
          this.$confirm("Yakin simpan produk ini?", 'Konfirmasi', 'warning').then(() =>{
          const request = {            
            orderNumber: this.queNum,
            token: this.token,
            keterangan: this.attributes,
          }
          console.log('request tambah catatan: ', request);
          callAPI(request, 'CASHIER PUSH ATTRIBUTE').then((response) => {
            console.log(response);
            if(response.outError != 0){
              this.$alert('Produk gagal ditambahkan', 'Gagal', 'error');
            }else{
              this.$alert('Produk telah ditambahkan', 'Berhasil', 'success');              
              this.note();
            }
          })
          this.$bvModal.hide("modalTambahCatatan");
          this.$bvModal.show("modalCatatan");
        })
      }else{
        this.$alert('Catatan tidak boleh kosong', 'Gagal', 'error')
      }
    },
    backNote(){      
      this.$bvModal.hide("modalTambahCatatan");
      this.$bvModal.show("modalCatatan");
    },
    getResult(value) {
      this.selectedVal = value
      console.log("Selected Val", this.selectedVal)
      this.newNote = {
        id: this.selectedVal.key,
        value: this.selectedVal.value
      }
    }, 
    addNote() {
      if (this.newNote == null || this.newNote == "") {
        this.$alert("Catatan tidak boleh kosong", 'Gagal', 'error');
        return;
      } else {
        this.attributes.push(this.newNote.value);
        this.newNote = null;
        this.$refs.CatatanModal.clearValue();
      }
    },
    delNote(index) {
      this.$confirm("Yakin hapus produk ini?", 'Konfirmasi', 'warning').then(() =>{
        this.attributes.splice(index, 1);
        this.$alert('Data telah terhapus', 'Berhasil', 'success')
      })
    }, 
    handleRecall: function () {
      console.log("this.queNum : ", this.queNum)
      this.$confirm("Lanjutkan panggil ulang ?", 'Konfirmasi', 'warning').then(() => {
        this.token = sessionStorage.getItem("token");
        
        const request = { token: this.token, orderNumber: this.queNum};
        callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
          console.log("response1 : ", response)
          if (response.outError == 0) {    
            this.$alert('Berhasil melakukan panggilan ulang', 'Berhasil', 'success');     
          } else {
            this.$alert('Gagal melakukan panggilan ulang', 'Gagal', 'error');
          }
        });
      });
    },
    save: function () {
      this.$bvModal.hide("modalCatatan");
      // if (this.selected.length > 0) {
        if(this.queCode == 'CO01' || this.queCode == 'CO02'){
          this.btnNext = 0;
          this.btnFinish = 1;
        }else{
          this.btnNext = 1;
          this.btnFinish = 1;
        }
      // }else{
      //   this.btnNext = 0;
      //   this.btnFinish = 0;
      // }
    },
    finish(value){
      const datafinish = {
        actionCode: value
      }
      this.textConfirm = "Yakin Lanjut Treatment?";
      this.alertMessage = "Melanjutkan nomor antrian";
      if(value == 10){
        this.submitFinish(datafinish);
      }else{
          this.textConfirm = "Yakin Selesaikan Transaksi?";
          this.alertMessage = "Menyelesaikan nomor antrian";
        if((this.queCode == 'CO01' || this.queCode == 'CO02' ) && !this.special){
          this.titleModal = 'Submit Phone Number';
          this.actionCode = value
          this.$bvModal.show("ModalPhone");
        }else{          
          this.submitFinish(datafinish);
        }
      }
    },
    submitFinish: function(value) {      
      this.$confirm(this.textConfirm, 'Konfirmasi', 'warning').then(() => {    
        const request = {
          token: this.token,
          orderNumber: this.queNum,
          actionCode: value.actionCode.toString(),
          paidAttributeId: this.selected,
        }
        let phoneNum = {          
          phoneNumber: value.phoneNumber
        }
        if(this.queCode == 'CO01' || this.queCode == 'CO02'){
          Object.assign(request, phoneNum)
        }
        console.log("req: ", request)
        callAPI(request, 'CASHIER PUSH QUEUE NUMBER').then((response) => {
          console.log('res: ', response);
          if (response.outError == 0) {
            this.$alert(this.alertMessage, 'Berhasil', 'success');
          } else {
            this.$alert(this.alertMessage, 'Gagal', 'error');
          }
          this.reload();
        });
      })
    }
  },
  destroyed() { 
    socket.off("connect");
    socket.off("disconnect");
    socket.off("share push queue");
  },
};
</script>

<style></style>
