<template>
  <div class="home">
    <b-container class="admin-page">
      <NavBar/>
      <HeadLogo />
      <b-row>
        <b-col>
          <center>
            <!-- <h3>Selamat Datang</h3>
            <h4>Token: {{ Token }}</h4>
            <h4>User: {{ User }}</h4> -->
            <p>Silahkan pilih menu diatas untuk melanjutkan.</p>
          </center>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import HeadLogo from "@/components/HeadLogo.vue"
var token;
var user;
export default {
  name: "Home",
  components: {
    HeadLogo,
    NavBar
  },
  beforeMount(){
    const token = sessionStorage.getItem('token')
    if (token == null) {
      return this.$router.push("/login")
    } else {
      return 
    }
  },
  computed: {
    
    Token: function(){
        token = sessionStorage.getItem ("token")
      return token
    },
    User: function(){
        user = sessionStorage.getItem ("user")
      return user
    },
  },
};

 
</script>
