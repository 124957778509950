<template> 
  <div>
    <div>
      <label class="d-inline-flex align-items-center">
        <b-form-input
          class="btn btn-md btn-hover" style="border:1px #ced4da solid;" :value="this.selectedVal.value" :placeholder="'Pilih '+title" @click="showModal" readonly type="search" />
          <b-input-group-append>
            <b-button class="form-control form-control-sm pt-1">
              <a
                @click="clearValue"
                >X</a>
            </b-button>
          </b-input-group-append>
      </label>
    </div>
    <b-modal no-close-on-backdrop :id="this.id" hide-footer @close="close">
      <template #modal-title>
        {{title}}
      </template>
      <div class="row">
        <div class="col">
          <div class="input-group input-group-sm mb-1">            
            <b-form-select 
              v-model="selected" 
              :options="options"
              class="form-control-lg"
              @change="filterType"
              ></b-form-select>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-1">
            <b-form-input
              v-on:keyup.enter="filterData"
              ref="fieldSearchLOV"
              type="search"
              placeholder="Search..."
              class="form-control-lg" />
            <b-button 
              class="btn btn-primary btn-sm text-white"              
                @click="filterData"
                data-placement="top"
                title="Search">
                Cari
            </b-button>
          </div>
        </div>
      </div>
      <div class="card p-1">
        <div class="table-responsive mb-0"  >
          <b-table  
            hover
            selectable
            ref="selectableTable"
            select-mode="single"
            :items="items"
            :fields="fields"
            responsive="sm"
            :per-page="0"
            :current-page="currentPage"
            @row-selected="this.rowSelected"
          >
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {callAPI} from "@/services/UserServices.js";
import "./LOVModal.css"
export default {
  name: "BeningsLOV",
  props: {
    id: String,
    title: String,
    code: String,
    keyName: String,
    valueKeyName: Array,
    val: Object,
    itemId: Object,
  },
  data() {
    return {
      selectedVal: this.val,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      items: [],
      tokens:"",
      filter: "",
      filterOn: [],
      fields: [
        { key: 'value', thClass: "small-font", sortable: false },
      ],
      selected: 0,
      options: [
        { value: 0, text: 'Pilih Tipe Catatan' },
        { value: 1, text: 'Product' },
        { value: 2, text: 'Treatment' }
      ]

    }
  },
  beforeMount(){    
    this.tokens = sessionStorage.getItem("token");
  },
  mounted() {
    this.setItems(this.currentPage,this.perPage,this.tokens,this.filter,this.selected)
  },
  methods: {
    close(){
      this.selected = 0
      this.filter = ''
    },
    clearValue(){
      this.selectedVal = {key: 0, value: ""}
      this.$emit("selectedVal", this.selectedVal)
    },
    showModal() {
      this.$bvModal.show(this.id)
      this.setItems(this.currentPage, this.perPage, this.tokens, this.filter,0)
    },
    rowSelected(items) {
      this.selectedVal = {key: items[0].key, value: items[0].value}
      this.$emit("selectedVal", this.selectedVal)
      this.$bvModal.hide(this.id)
    },
    changePageRow(){
      console.log("Seleksi: ", this.selected)
      this.setItems(this.currentPage, this.perPage, this.tokens, this.filter, this.selected)
    },
    setItems(p_page_number, p_row_per_page,p_token, p_search, p_att_type){
      const request = {
            pageNumber: p_page_number,
            rowPerPage: p_row_per_page,
            token: p_token,
            searchValue: p_search,
            attributeType: p_att_type
      }
      // Object.assign(request, this.itemId)
      console.log("request baru: ", request)
      callAPI(request,this.code).then((response) =>{
        this.items = []
        console.log('respon get attribute', response);
        if (response.outError != 0){
          console.log(response.outMessage)
          this.items = []
          return
        }
        for (let i = 0; i < response.outData.length; i++) {
          let obj = {}
          let objVal = ""
          for(const keys in response.outData[i]) {
            if (keys.includes(this.keyName)) {
              Object.assign(obj, {key: response.outData[i][keys]})
            }
            for (let k = 0; k < this.valueKeyName.length; k++) {
              if (k != this.valueKeyName.length-1) {
                if (keys.includes(this.valueKeyName[k])) {
                  objVal = response.outData[i][keys] + " - "
                }
              } else if(keys.includes(this.valueKeyName[k])) {
                objVal = objVal + response.outData[i][keys]
              }
            }
          }
          Object.assign(obj, {value: objVal})
          this.items.push(obj)
        }
        console.log(this.items)
        this.totalRows = response.outCount
        console.log(this.totalRows)
        if (this.selectedVal.key != 0) {
          for (let i = 0; i < this.items.length; i++) {
            if (this.selectedVal.key === this.items[i].key) {
              this.selectedVal= this.items[i]
            }
          }
        }
      })
    },
    filterData(){
      this.filter = this.$refs.fieldSearchLOV.$refs.input._value
      this.setItems(1, this.perPage, this.tokens, this.filter,this.selected)
    },
    filterType(){      
      this.setItems(1, this.perPage, this.tokens, this.filter,this.selected)
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        console.log("didalem event benings LOV")
        this.currentPage = value
        this.setItems(this.currentPage,this.perPage,this.tokens,this.filter,this.selected)
      }
    },
  }
};
</script>
