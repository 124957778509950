<template>
  <b-container class="mt-3 admin-page">
    <NavBar />
    <b-row>
      <b-col>
        <b-button
        :disabled="this.queBtnStatus > 0"
          href="#"
          variant="outline-secondary"
          class="btn-antrian antrian"
          block
          @click.prevent="handleQue">
          Antrian Selanjutnya
        </b-button>
      </b-col>
      <b-col>        
        <b-button disabled class="antrian-box mb-2">
          <p>Nomor Antrian Sekarang</p>
          <h1>{{ queNum }}</h1>
          <span>{{ queNumCat }}</span>
        </b-button>
        <b-button disabled class="antrian-box">
          <h1>{{ countQueNum }}</h1>
          <p>Jumlah Antrian Menunggu</p>
        </b-button>
      </b-col>
    </b-row>
      <b-button
      href="#"
      variant="outline-secondary"
      class="antrian-recall"
      block
      @click.prevent="handleRecall"
      v-if="this.queNum"
      >
      Panggil Ulang
      </b-button>
      <b-button
      href="#"
      variant="outline-secondary"
      class="antrian-recall"
      block
      @click.prevent="handleDocQue(1)"
      >
      Antrian Dokter
      </b-button>
    <form @submit.prevent="handleSubmit">
      <b-row class="mt-3" v-if="this.skipMenu">
        <b-col>
          <v-select
            :options="options"
            :placeholder="placeholder"
            v-model="checked"
            value-field="item"
            text-field="label"
            @input="onChecked"
            @click="skipQue"
          ></v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
          :hidden="this.queNum != null || this.vipStatus > 0 || this.queBtnStatus > 0"
          href="#"
          variant="outline-secondary"
          class="vip-recall"
          block
          @click.prevent="handleVIP"
          >
          VIP
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group class="btn-radio-admin" v-if="status > 0">
            <b-form-radio
              v-model="selected"
              button
              name="push-menu"
              value="02"
              class="btn-radio-admin-1"
              @change="inSelected()"
              >Dokter</b-form-radio>
            <b-form-radio
              v-model="selected"
              button
              name="push-menu"
              value="03"
              class="btn-radio-admin-1"
              @change="inSelected()"
              >Nurse</b-form-radio>
            <b-form-radio
              v-model="selected"
              button
              name="push-menu"
              value="01"
              class="btn-radio-admin-1"
              @change="inSelected()"
              >Kasir</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="this.noteStatus > 0">
        <b-col>
          <b-button
            block
            pill
            class="mt-2"
            @click.prevent="openModalCatatan"
          >
          Tambah Catatan
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="this.submitStatus > 0">
          <b-button
            block
            pill
            class="mt-2"
            type="submit"
            >{{ submitBtn }}</b-button
          >
        </b-col>
      </b-row>
    </form>
    <NoteModal 
    ref="ModalCatatan" 
    :titleProps="this.titleModal" 
    :numberProps="this.orderNumber" 
    :noteProps="this.attrModal" 
    :typeProps=1
    @getNote="getNote"/>

    <AntrianDokterModal 
    ref="ModalAntrianDokter" 
    :titleProps="this.titleModal" 
    :numberProps="this.queNum" 
    :codeProps="this.actionCode" 
    :modalTypeProps="this.modalType" 
    @toDoctor="toDoctor"/>
    
    <PhoneModal 
    ref="ModalPhone" 
    :titleProps="this.titleModal" 
    :numberProps="this.queNum" 
    :codeProps="this.actionCode" 
    :noteProps="this.noteArr" 
    :doctorProps="this.doctorId" 
    :categoryProps="this.queNumCat"
    @submitFinish="submitFinish"/>
  </b-container>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from "../../services/UserServices.js";
import NoteModal from "@/components/NoteModal.vue";
import AntrianDokterModal from "@/components/AntrianDokterModal.vue";
import PhoneModal from '@/components/PhoneModal.vue';
import socket from "../../socket";
export default {
  name: "Frontline",
  components: {
    NavBar,
    NoteModal,
    AntrianDokterModal,
    PhoneModal
  },
  data() {
    return {
      options: [],
      orderNumber: null,
      checked: "",
      selected: "",
      token: null,
      queNum: null,
      queNumCat: "",
      queNumCode: null,
      quePhone: null,
      countQueNum: null,
      placeholder: null,
      noteArr: [],
      attrModal:[],
      actionCode:"",
      status: 0,
      queBtnStatus:0,
      vipStatus: 0,
      submitStatus:0,
      noteStatus: 0,
      titleModal:"",
      valueCode:0,
      textConfirm:"",
      modalType: 0,
      doctorId:null,
      vipNum: null,
      alertMessage:"Melanjutkan nomor antrian",
      skipMenu: true,
      submitBtn: "Lanjutkan",
    };
  },
  beforeMount() {
    this.token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");
    this.placeholder = "Pilih antrian terlewat";
    if (this.token == null) {
      this.$router.push("/login");
    } else if (user != "ADM") {
      this.$router.push("/home");
    } else {
      this.skipQue();
      this.getCountQueNum();
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token};
    socket.connect();

    socket.on("connect", () => {
      console.log("Handshake success admin")
    });
    socket.on("share push queue", (data) => {
      this.countQueNum = data.count
    });
  },
  methods: {
    openModalCatatan(){
      console.log("order number: ", this.orderNumber)
      if(this.queNum == null){
        this.orderNumber = this.checked.value.order_number
      }else{
        this.orderNumber = this.queNum
      }
      if(this.noteArr.length > 0){
        for(let x = 0;x < this.noteArr.length;x++){
          this.attrModal.push({
              keterangan: this.noteArr[x]
              })
        }
      }
      this.$refs.ModalCatatan.load();
      this.titleModal = "Catatan";
      this.$bvModal.show("ModalCatatan");
    }, 
    getNote(data){
      // if(data.length > 0){      
      this.submitStatus = 1;
      console.log("isi data: ", data);
      this.noteArr = data;      
      this.attrModal = []
      // }else{
      //   if(this.selected == '02'){
      //     this.submitStatus = 1
      //   }else{
      //     this.submitStatus = 0
      //   }
      // }
    },
    reload(){
      this.skipQue();
      this.getCountQueNum();
      this.queNum = null,
      this.queNumCat = null,
      this.queNumCat = "",
      this.quePhone = null,
      this.placeholder = "Pilih antrian terlewat";
      this.queBtnStatus = 0
      this.status = 0
      this.selected = 0
      this.vipStatus = 0
      this.submitStatus = 0
      this.skipMenu = true
      this.noteStatus = 0  
      this.attrModal = []
      this.noteArr = [];
      this.checked = null
      this.submitBtn = "Lanjutkan"
      this.$refs.ModalPhone.load()
      this.$refs.ModalCatatan.reset()
    },
    skipQue: function () {
      const request = {
        token: this.token,
        searchValue: "",
      };
      callAPI(request, 'ADMIN LOV CUSTOM QUEUE').then((response) => {
        let optionVal = []
        if(response.outData != null){  
          for(let x=0;x<response.outData.length;x++){
            let dataSkip = {
              label: response.outData[x].order_number + ' - ' + response.outData[x].category_name,
              value: response.outData[x]
            }
            optionVal.push(dataSkip)
          }   
          this.options = optionVal
          console.log("option: ", this.options)   
        }
      });
    },
    onChecked: function () {
      console.log('nomor terlewat: ',this.checked)
      this.attrModal = [];
      if(this.checked){   
        this.queNum = this.checked.value.order_number;
        this.queNumCat = this.checked.value.category_name;     
        this.quePhone = this.checked.value.phone_number;
        this.queNumCode = this.checked.value.category_code;
        if (this.checked.value.category_code == 'CO05') {
          this.status = 1;
          this.vipStatus = 1;
          this.submitStatus = 0;
          this.selected = '';
          this.noteStatus = 0;
          this.queBtnStatus = 1;
          this.getAttribute(this.checked.value.order_number);
        }else{
          this.selected = '02';
          this.status = 0;
          this.vipStatus = 1;
          this.submitStatus = 1;
          this.noteStatus = 0;
          this.queBtnStatus = 1;
          this.submitBtn = "Lanjutkan ke Dokter"
        }
      }else{
          this.reload();

      }
    },
    inSelected(){      
        this.noteStatus = 1;
        this.submitStatus = 0;
        switch(this.selected){
          case "01":
          return  this.submitBtn = "Lanjutkan ke Kasir";
          case "02":
          return  this.submitBtn = "Lanjutkan ke Dokter";
          case "03":
          return  this.submitBtn = "Lanjutkan ke Nurse";
          default:
          return  this.submitBtn = "Lanjutkan";
        }
    },
    getCountQueNum: function(){
      const request = {
        token: this.token
      }
      callAPI(request, 'ADMIN GET COUNT QUEUE NUMBER').then((response) => {
        if(response.outError != 0){
          this.$alert(response.outMessage);
        }else{
          this.countQueNum = response.outCount;
        }
      })
    },
    handleVIP(){
        this.textConfirm = "Yakin Buat Antrian VIP?";
        this.$confirm(this.textConfirm, 'Konfirmasi', 'warning').then(() => { 
          this.queNumCat = 'VIP'
        this.phoneHandler(null, null, null,0,this.queNumCat)
      })
    },
    handleQue: function () {
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token };
        callAPI(request, 'ADMIN GET QUEUE NUMBER').then((response) => {
          if (response.outError == 0) {          
            console.log("Response Antrian: ", response)  
            this.queNum = response.outOrderNumber;
            this.queNumCat = response.outCategoryName;
            this.queNumCode = response.outCategoryCode;
            if(this.queNumCode == 'CO05'){
              this.status = 1;
              this.submitStatus = 0;
              this.noteStatus = 0;
              this.selected = '';
            }else{
              this.status = 0;
              this.submitStatus = 1;
              this.selected = '02';
              this.submitBtn = "Lanjutkan ke Dokter"
            }
            this.skipMenu = false;
            this.vipStatus = 1;
          } else {
            this.$alert('Belum ada antrian!', 'Gagal', 'error');
          }
          this.skipQue();
          // this.getCountQueNum();
        });
      });
    },
    getAttribute(val){
        const request = {
            token: this.token,
            orderNumber: val
        }
        callAPI(request, 'ADMIN GET ATTRIBUTE').then((response) => {
            console.log('res attr: ', response)
            if(response.outError == 0){
                console.log('data attr: ', response.outData);
                for(let x = 0;x < response.outData.length;x++){
                    this.attrModal.push({
                        keterangan: response.outData[x].attribute,
                        is_remove: 1
                        })
                }
            }else{
                console.log(response.outMessage);
            }
        })
    },
    handleDocQue(type){
      if(type == 1){        
        this.titleModal = "Antrian Dokter";
      }else{
        this.titleModal = "Pilih Dokter";
      }      
      this.modalType = type
      this.$refs.ModalAntrianDokter.load()
      this.$bvModal.show("ModalAntrianDokter");
    },
    handleRecall: function () {
      console.log("this.queNum : ", this.queNum)
      this.$confirm("Lanjutkan panggil ulang ?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token, orderNumber: this.queNum};
        callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
          console.log("response1 : ", response)
          if (response.outError == 0) {    
            this.$alert('Berhasil panggil ulang!', 'Berhasil', 'success')
          } else {
            this.$alert('Tidak ada nomor!', 'Gagal', 'error');
          }          
          this.skipQue();
          this.getCountQueNum();
        });
      });
    },
    handleSubmit: function () {
      this.$confirm("Yakin melanjutkan?", 'Konfirmasi', 'warning').then(() => {
        this.actionCode = this.selected;
        if(this.queNum == null){
          this.queNum = this.checked.value.order_number
        }  
       if(this.actionCode == '02'){
          this.handleDocQue(2);
       }else{
        if(this.quePhone == null){
          this.phoneHandler(this.queNum, this.actionCode, this.noteArr,0)
        }else{
          console.log("masuk sini")
          const data = {
            orderNumber: this.queNum,
            actionCode: this.actionCode,
            phoneNumber: this.quePhone,
            note: this.noteArr,
            doctorId: 0
          };
          this.submitFinish(data);
        }
       }        
      });
    },
    toDoctor(data){
      console.log("data dari modal dokter: ", data)
      console.log('code: ', this.queNumCode)
      if(this.queNumCode != 'CO05'){
        this.noteArr = [];
      }         
      if(this.quePhone == null){
        this.phoneHandler(data.queNum, data.actionCode, this.noteArr, data.doctorId);
      }else{
        const value = {
          orderNumber: data.queNum,
          actionCode: data.actionCode,
          phoneNumber: this.quePhone,
          note: this.noteArr,
          doctorId: data.doctorId
        };
        console.log('isi value: ', value);
        this.submitFinish(value);
      }
    },
    phoneHandler(...data){      
      console.log("isi dari data phone: ", data);
      this.titleModal = "Submit Phone Number";
      this.queNum = data[0];
      this.actionCode = data[1]
      this.noteArr = data[2]
      this.doctorId = data[3] 
      this.queNumCat = data[4]      
      this.$bvModal.show('ModalPhone');
    },
    submitFinish(val){
     if(val.category != 'VIP' || val.category == null){
       const request = {
        token: this.token,
        orderNumber: val.orderNumber,
        actionCode: val.actionCode,
        phoneNumber: val.phoneNumber,
        keterangan: val.note,
        doctorId: val.doctorId
      }
      console.log('Request Push Admin: ', request)
      callAPI(request, 'ADMIN PUSH QUEUE NUMBER').then((response) => {
        console.log('res submit: ',response.outMessage)
        if(response.outError == 0){
          this.$alert(this.alertMessage, 'Berhasil', 'success'); 
        }else{
          this.$alert(this.alertMessage, 'Gagal', 'error');
        }
        this.reload();
      })
     }else{
      console.log('isi val: ', val)
      const request = {token: this.token, phoneNumber: val.phoneNumber}
        callAPI(request, "GENERATE_QUEUE_NUMBER_VIP").then((response) => {
            console.log(response) 
          if(response.outError == 0){
            this.$alert('Membuat antrian VIP', 'Berhasil', 'success');
          }else{           
            this.$alert('Gagal membuat antrian VIP', 'Gagal', 'error');
          }
          this.reload();
        })
     }
    }
  },
};
</script>