<template>
    <b-container class="mt-3 admin-page">
        <NavBar />
        <b-row>
            <b-col>
                <b-button
                :disabled="this.queBtnStatus > 0"
                href="#"
                variant="outline-secondary"
                class="btn-antrian antrian"
                block
                @click="handleQue"
                >
                Antrian Selanjutnya
                </b-button>
            </b-col>
            <b-col>
                <b-button disabled class="antrian-box mb-2">
                <p>Nomor Antrian Sekarang</p>
                <h1>{{ queNum }}</h1>
                <span>{{queCategory}}</span>
                </b-button>
                <b-button disabled class="antrian-box">
                <h1>{{ countQueNum }}</h1>
                <p>Jumlah Antrian Menunggu</p>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-button
                href="#"
                variant="outline-secondary"
                class="antrian-recall"
                block
                @click.prevent="handleRecall"
                v-if="this.queNum"
                >Panggil Ulang</b-button>
            </b-col>
        </b-row>
        <form @submit.prevent="handleSubmit">
            <b-row class="mt-3" v-if="this.skipStatus == 0">
                <b-col>
                <v-select
                    :options="options"
                    :placeholder="placeholder"
                    v-model="checked"
                    value-field="item"
                    text-field="label"
                    @input="onChecked"
                    @click="skipQue"
                ></v-select>
                </b-col>
            </b-row>
            <b-row v-if="noteStatus > 0">
                <b-col>
                    <b-button
                        block
                        pill
                        class="mt-2"
                        @click.prevent="openModalCatatan"
                    >
                    Tambah Catatan
                    </b-button>
                </b-col>
            </b-row>
            <b-row v-if="submitStatus > 0">
                <b-col>
                    <b-button
                        block
                        pill
                        class="mt-2"
                        type="submit"
                    >Lanjutkan ke Kasir</b-button>
                </b-col>
            </b-row>
        </form>
        <NoteModal 
        ref="ModalCatatan" 
        :titleProps="this.titleModal" 
        :numberProps="this.orderNumber" 
        :noteProps="this.attrModal"
        :typeProps=1
        @getNote="getNote"/>
        <PhoneModal ref="ModalPhone" 
        :titleProps="this.titleModal" 
        :numberProps="this.queNum" 
        :codeProps="this.actionCode" 
        :noteProps="this.noteArr" 
        @submitFinish="submitFinish"/>
    </b-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from '../../services/UserServices';
import NoteModal from '@/components/NoteModal.vue';
import PhoneModal from '@/components/PhoneModal.vue';
import socket from '../../socket';
export default {
    name: 'ProdSpesialist',
    components:{
        NavBar,
        NoteModal,
        PhoneModal
    },
    data(){
        return {
            token: null,
            queNum: null,
            queCategory: null,
            queCategoryCode: null,
            countQueNum: null,
            orderNumber: null,
            actionCode:'',
            options: [],
            checked:'',
            placeholder: '',
            titleModal: '',
            noteArr: [],
            attrModal:[],
            submitStatus: 0,
            noteStatus: 0,
            queBtnStatus: 0,
            skipStatus: 0,
            alertMessage: 'Melanjutkan nomor antrian'
        }
    },
    beforeMount(){
        this.token = sessionStorage.getItem('token');
        this.user = sessionStorage.getItem('user');
        this.placeholder = 'Pilih antrian terlewat';
        if(this.token == null){
            this.$router.push('/login');
        }else if(this.user != 'SLS'){
            this.$router.push('/home');
        }else{
            this.skipQue();
            this.getCountQueNum();
        }
    },
    mounted(){
        socket.disconnect();
        socket.auth = {
            token: this.token
        }
        socket.connect();

        socket.on('connect', () => {
            console.log('Handshake success sales');
        });
        socket.on('share push queue', (data) => {
            this.countQueNum = data.count
        })
    },
    methods: {
        openModalCatatan(){
            console.log('nomor antrian: ', this.queNum);
            if(this.queNum == null){
                this.orderNumber = this.checked.value.order_number
            }else{
                this.orderNumber = this.queNum
            }
            if(this.noteArr.length > 0){
                for(let x = 0;x < this.noteArr.length;x++){
                this.attrModal.push({
                    keterangan: this.noteArr[x]
                    })
                }
            }
            this.titleModal = 'Tambah Catatan';
            this.$refs.ModalCatatan.load();
            this.$bvModal.show("ModalCatatan");
        },
        getNote(data){
            // if(data.length > 0){
                this.submitStatus = 1;
                console.log("isi data: ", data);
                this.noteArr = data;
            // }else{
            //     this.submitStatus = 0;
            // }
        },
        reload(){
            this.skipQue();
            this.getCountQueNum();
            this.queNum = null;
            this.queCategory = null;
            this.placeholder = 'Pilih antrian terlewat';
            this.submitStatus = 0;
            this.noteStatus = 0;
            this.queBtnStatus = 0;
            this.skipStatus = 0;
            this.checked = null;
            this.attrModal = [];
            this.noteArr = [];
            this.$refs.ModalPhone.load()
            this.$refs.ModalCatatan.reset()
        },
        skipQue(){
            const request = {
                token: this.token,
                searchValue: ''
            };
            callAPI(request, 'SALES LOV CUSTOM QUEUE').then((response) => {
                let optionVal = []
                if(response.outData != null){
                    for(let x = 0; x < response.outData.length; x++){
                        let dataSkip = {
                            label: response.outData[x].order_number +' - '+response.outData[x].category_name,
                            value: response.outData[x]
                        }
                        optionVal.push(dataSkip)
                    }
                    this.options = optionVal
                    console.log('option: ', this.options)
                }
            });
        },
        onChecked(){
            console.log('nomor terlewat: ',this.checked)
            this.attrModal = []
            if(this.checked){                
                this.queBtnStatus = 1;
                this.noteStatus = 1;
                this.submitStatus = 0;   
                this.queNum = this.checked.value.order_number;
                this.queCategory = this.checked.value.category_name;                             
                if(this.checked.value.category_code != 'CO01'){
                    this.getAttribute(this.checked.value.order_number);
                }
            }else{                
                this.queBtnStatus = 0;
                this.noteStatus = 0;
                this.submitStatus = 0;
                this.attrModal = []
            }
        },
        getCountQueNum(){
            const request = {
                token: this.token
            }
            callAPI(request, 'SALES GET COUNT').then((response) => {
                if(response.outError != 0){
                    this.$alert(response.outMessage);
                }else{
                    this.countQueNum = response.outCount;
                }
            })
        },
        handleQue(){
            this.$confirm('Panggil antrian selanjutnya?', 'Konfirmasi', 'warning').then(() => {
                const request = {
                    token: this.token
                }
                callAPI(request, 'SALES GET QUEUE NUMBER').then((response) => {
                    if(response.outError == 0){
                        console.log('res antrian: ', response);
                        this.queNum = response.outOrderNumber;
                        this.queCategory = response.outCategoryName;
                        this.queCategoryCode = response.outCategoryCode;                
                        this.submitStatus = 0;
                        this.noteStatus = 1;
                        this.skipStatus = 1;
                        if(this.queCategoryCode != 'CO01'){
                            this.getAttribute(this.queNum);
                        }
                    }else{
                        this.$alert('Belum ada antrian!', 'Gagal', 'error');
                    }
                    this.skipQue();
                })
            })
        },
        getAttribute(val){
            const request = {
                token: this.token,
                orderNumber: val
            }
            callAPI(request, 'SALES GET ATTRIBUTE').then((response) => {
                console.log('res attr: ', response)
                if(response.outError == 0){
                    console.log('data attr: ', response.outData);
                    for(let x = 0;x < response.outData.length;x++){
                        this.attrModal.push({
                            keterangan: response.outData[x].attribute,
                            is_remove: 1
                            })
                    }
                }else{
                    console.log(response.outMessage);
                }
            })
        },
        handleRecall(){
            console.log('panggil ulang no: ', this.queNum);
            this.$confirm('Panggil Ulang?', 'Konfirmasi', 'warning').then(() => {
                const request = {
                    token: this.token,
                    orderNumber: this.queNum
                }
                callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
                        console.log('res recall: ', response);
                    if(response.outError == 0){
                        this.$alert('Berhasil memanggil ulang', 'Berhasil', 'success');
                    }else{
                        this.$alert('Gagal memanggil ulang', 'Gagal', 'error');
                    }
                    this.skipQue();
                })
            })
        },
        handleSubmit(){
            this.$confirm('Lanjutkan Transaksi?', 'Konfirmasi', 'warning').then(() => {
                this.actionCode = '01'
                if(this.queNum == null){
                    this.queNum = this.checked.value.order_number;
                    this.queCategoryCode = this.checked.value.category_code;
                }
                console.log('no: '+this.queNum+' - '+this.queCategoryCode)
                if(this.queCategoryCode != 'CO01'){
                    const data = {
                        orderNumber: this.queNum,
                        actionCode: this.actionCode,
                        phoneNumber: '',
                        note: this.noteArr
                    }
                    this.submitFinish(data);
                }else{                    
                    this.phoneHandler(this.queNum, this.actionCode, this.noteArr,0);
                }
            })
        },
        phoneHandler(...data){
            console.log('isi dari data phone: ', data);
            this.titleModal = 'Submit Phone Number';
            this.queNum = data[0];
            this.actionCode = data[1];
            this.noteArr = data[2];
            this.$bvModal.show("ModalPhone");
        },
        submitFinish(val){
            const request = {
                token: this.token,
                orderNumber: val.orderNumber,
                actionCode: val.actionCode,
                phoneNumber: val.phoneNumber,
                keterangan: val.note,
            }
            console.log('req push sales: ', request);
            callAPI(request, 'SALES PUSH QUEUE NUMBER').then((response) => {
                if(response.outError == 0){
                    this.$alert(this.alertMessage, 'Berhasil', 'success');
                }else{
                    this.$alert(this.alertMessage, 'Gagal', 'error');
                }
                this.reload();
            })
        }

    },
    destroyed(){
        socket.off("connect");
        socket.off("disconnect");
        socket.off("share push queue");
    }
}
</script>