<template>
  <div class="screen">
    <b-container>
      <b-row class="text-center">
        <b-col cols="2" class="screen-bawah">
          <b-button @click="fullscreen" class="antrian-box-screen" block v-for="(data, i) in dataAntrian.filter(item => item.sequenceNumber % 2 != 0)" :key="i" :class="{blinking: target == data.number && targetText == data.title}">
            <p v-show="data.title">Nomor</p>
            <h1>{{ data.number }}</h1>
            <p>{{ data.title }}</p>
          </b-button>
        </b-col>
        <b-col cols="8">
          <Screen />
        </b-col>
        <b-col cols="2" class="screen-bawah">
          <b-button class="antrian-box-screen" block v-for="(data, i) in dataAntrian.filter(item => item.sequenceNumber % 2 == 0)" :key="i" :class="{blinking: target == data.number && targetText == data.title}">
            <p v-show="data.title">Nomor</p>
            <h1 >{{ data.number }}</h1>
            <p >{{ data.title }}</p>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import socket from "../socket"; 
import Screen from "@/components/Screen.vue";

var audioPlay = new Audio("audios/notification.mp3");
export default {
  name: "ScreenBawah",
  components: {
    Screen,
  },
  data() {
    return {
      dataAntrian: [
        {
          sequenceNumber: 0
        }
      ],
      token: null,
      user: null,
      screenId: null,
      target: null,
      targetText: null,
      pushed : null
    };
  },
  beforeMount() {
    this.token = sessionStorage.getItem("token");
    this.user = sessionStorage.getItem("user");
    this.screenId = sessionStorage.getItem('screen');
    if (this.token == null) {
      return this.$router.push("/login");
    } else if (this.user != "DS") {
      return this.$router.push("/home");
    }
  },
  methods:{
    fullscreen: function (){
      document.documentElement.requestFullscreen().catch((e) => {
        console.log(e);
      });
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token, screenId: this.screenId};
    socket.connect();
    let msg = new SpeechSynthesisUtterance();
    let voices = window.speechSynthesis.getVoices();

    document.documentElement.requestFullscreen().catch((e) => {
      console.log(e);
   });

    socket.on("connect", () => {
      console.log("Handshake success")
    })

    socket.on("get init", (data) => {
      this.dataAntrian = data
    })

    socket.on("share push screen", (data) => {
      // this.target = null;
      this.dataAntrian = data.data;
      console.log('isi dataAntrian: ',this.dataAntrian);
      console.log('isi data: ',data);
      console.log('isi target: ', data.numberTarget);
      console.log('isi title: ', data.titleTarget)
      this.target = data.numberTarget;
      this.targetText = data.titleTarget;
      console.log('isi text: ', this.targetText)
      audioPlay.play();
      var reset = () => {
          this.target = null
          this.targetText = null
        }
      setTimeout(function() {        
        var voice = async function () {
          msg.text = data.voice;
          msg.lang = "id"
          msg.voice = voices[11]
          await window.speechSynthesis.speak(msg);
        }
        voice()
        setTimeout(function(){
          reset()
        }, 8000)
      }, 1500);
      });
  },
  destroyed() { 
    socket.off("connect");
    socket.off("disconnect");
    socket.off("get init");
    socket.off("share push screen");
  },
};
</script>

<style>
</style>
