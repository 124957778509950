<template>
  <b-container class="mt-3 admin-page">
    <NavBar />
    <b-row>
      <b-col>
        <b-button
          href="#"
          variant="outline-secondary"
          class="btn-antrian antrian"
          block
          v-on:click="nextQueue"
          >Antrian Selanjutnya</b-button
        >
      </b-col>
      <b-col>
        <b-button disabled class="antrian-box mb-2">
          <p>Nomor Antrian Sekarang</p>
          <h1>{{ orderNumber }}</h1>
          <span>{{queCategory}}</span>
        </b-button>
        <b-button disabled class="antrian-box">
          <h1>{{ countQueueNumber }}</h1>
          <p>Jumlah Antrian Menunggu</p>
        </b-button>
      </b-col>
    </b-row>
    <b-button
    href="#"
    variant="outline-secondary"
    class="antrian-recall"
    block
    @click.prevent="nextVIP('VIP')"
    >
    Panggil VIP : {{vipCount}}
    </b-button>
    <b-button
    href="#"
    variant="outline-secondary"
    class="antrian-recall"
    block
    @click.prevent="handleRecall"
    v-if="this.orderNumber"
    >
    Panggil Ulang
    </b-button>
    <b-row class="mt-3">
      <b-col :hidden="noteBtn == 0">
        <h5>Catatan Dokter:</h5>
        <b-button
          v-b-modal.modalcatatan
          variant="outline-secondary"
          pill
          block
          v-on:click="note"
          >Buka Catatan</b-button
        >
      </b-col>
    </b-row>
    <b-modal id="modalcatatan" centered hide-header ok-only>
      <form action="#">
        <h3>Catatan Dokter:</h3>
        <tbody>
          <tr v-for="(i, index) in items" :key="index">
            <td>{{ i.catatan }}</td>
          </tr>
        </tbody>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from "../../services/UserServices.js";
import socket from "../../socket";

export default {
  name: "NurseStation",
  components: {
    NavBar,
  },
  data() {
    return {
      items: [
        {
          catatan: "",
        },
      ],
      token: null,
      orderNumber: null,
      queCategory: null,
      countQueueNumber: null,
      modalcatatan: false,
      noteBtn: 0,
      alertMessage: "Menyelesaikan nomor antrian",
      vipCount: 0
    };
  },
  beforeMount() {
    this.token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");
    if (this.token == null) {
      return this.$router.push("/login");
    } else if (user != "NS") {
      return this.$router.push("/home");
    } else {
      this.orderNumber = null;
      this.vipCount = 0;
      this.noteBtn = 0;
      this.getTotQueNum();
      this.getVipQue();
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token};
    socket.connect();

    socket.on("connect", () => {
      console.log("Handshake success Nurse")
    });

    socket.on("share push queue", (data) => {
      if(data.queueType == "VIP"){
        this.vipCount = data.count
      }else{
        this.countQueueNumber = data.count        
      }
        console.log("isi data socket: ", data)
    });
  },
  methods: {
    getTotQueNum: function () {
      const request = {
        token: this.token,
      };
      callAPI(request, 'NURSE GET COUNT QUEUE NUMBER').then((response) => {
        console.log('res counting: ',response)
        if (response.outError > 0) {
          this.$alert(response.outMessage);
        } else {
          this.countQueueNumber = response.outCount;
        }
      });
    },
    getVipQue(){
      const request = {
        token: this.token,
        categoryCode: 'VIP'
      };
      callAPI(request, 'NURSE GET COUNT QUEUE NUMBER').then((response) => {
        console.log('res vip: ',response)
        if (response.outError > 0) {
          this.$alert(response.outMessage);
        } else {
          this.vipCount = response.outCount;
        }
      });
    },
    nextQueue: function () {
      this.queCategory = null,
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = {
          token: this.token,
        };
        callAPI(request, 'NURSE GET QUEUE NUMBER').then((response) => {
          if (response.outError > 0) {
            this.$alert('Belum ada antrian', 'Gagal', 'error');
          } else {
            this.orderNumber = response.outOrderNumber;
            this.queCategory = response.outCategoryName;
            this.noteBtn = 1;
          }
        });
      });
    },
    nextVIP: function (val) {
      this.queCategory = null,
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = {
          token: this.token,
          categoryCode: val
        };
        callAPI(request, 'NURSE GET QUEUE NUMBER').then((response) => {
          if (response.outError > 0) {
            this.$alert('Belum ada antrian', 'Gagal', 'error');
          } else {
            this.orderNumber = response.outOrderNumber;
            this.queCategory = response.outCategoryName;
            this.noteBtn = 0;
          }
        });
      });
    },
    handleRecall: function () {
      console.log("this.queNum : ", this.orderNumber)
      this.$confirm("Lanjutkan panggil ulang ?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token, orderNumber: this.orderNumber};
        callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
          console.log("response1 : ", response)
          if (response.outError == 0) {                     
            this.$alert('Berhasil memanggil ulang', 'Berhasil', 'success');
          } else {
            this.$alert('Gagal memanggil ulang', 'Gagal', 'error');
          }
        });
      });
    },
    //save item
    note: function () {
      this.items = []
      const request = {
        orderNumber: this.orderNumber,
        token: this.token,
      };
      callAPI(request, 'NURSE GET ATTRIBUTE').then((response) => {
        if (response.outError > 0) {
          this.$alert(response.outMessage, 'Berhasil', 'success');
          this.items = []
        } else {
          this.items = [
            {
              catatan: "",
            },
          ];
          for (let i = 0; i < response.outData.length; i++) {
            this.items.push({
              catatan: response.outData[i].attribute,
            });
          }
        }
      });
    },
    // hide modal
    handleModal() {
      this.modalcatatan = false;
    },
  },
};
</script>

<style>
</style>