<template>
    <b-modal id="ModalPhone" centered hide-footer hide-header no-close-on-backdrop>
        <h4>{{titleProps}} - {{numberProps}}</h4>
        <b-row>
            <b-col>
                <b-form-group
                    id="noHp"
                    label="No Hp:"
                    label-for="noHp"
                    description="We'll never share your phone with anyone else.">
                    <b-form-input
                        id="noHp"
                        v-model="dataModal.phoneNumber"
                        placeholder="Input Phone Number"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="justify-content-end">
            <b-col cols="2" class="mr-1">                
                <b-button size="sm" variant="secondary" @click="next">
                    Submit
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
  name: "PhoneModal",
  props: ['titleProps','numberProps', 'codeProps', 'noteProps','doctorProps', 'categoryProps'],
  components: {    
  },
  data(){
    return{
        dataModal :{},
    }
  },
  methods:{ 
    load: function() {
        this.dataModal.phoneNumber = ''
    },
    next: function() {
        console.log('isi cat: ', this.categoryProps)
        if(this.dataModal.phoneNumber){
            if(this.categoryProps != 'VIP'){
                this.dataModal.orderNumber = this.numberProps
                this.dataModal.actionCode = this.codeProps
                this.dataModal.note = this.noteProps
                this.dataModal.doctorId = this.doctorProps
                console.log("dataModal: ", this.dataModal);

            }
                this.dataModal.category = this.categoryProps;
            
                this.$emit('submitFinish', this.dataModal);
                this.$bvModal.hide("ModalPhone");
        }else{
            this.$alert('No Handphone harus diisi', 'Gagal', 'error');
            return
        }
    }
  }

};
</script>

<style>
</style>