<template>
  <b-row>
    <b-col>
      <b-modal 
        id="ModalCatatan" 
        centered 
        hide-footer 
        no-close-on-backdrop 
        no-close-on-esc 
        v-bind:title="titleProps"
        hide-header-close
        >
        <h5>No. {{numberProps}}</h5>
        <hr>
        <form>
            <b-row class="mt-3">
              <b-col cols="8">       
                  <div v-if="typeProps == 1">       
                  <DoctorLOV :id="'CatatanModal'" :title="'Catatan Dokter'" :code="'GET ATTRIBUTE'" :valueKeyName="['name']" :keyName="'code'" :val="{key: this.options.attribute_code, value: this.options.attribute_name}" @selectedVal="getResult" ref="CatatanModal"/>
                  </div>
                  <div v-if="typeProps == 2">               
                    <b-form-input
                    id="newNote"
                    v-model="newNote"
                    type="text"
                    placeholder="Tambah Catatan"
                    required
                    @keyup.enter="addNote()"
                  ></b-form-input>
                  </div>
              </b-col>
              <b-col cols="4">
                  <b-button variant="outline-success" @click="addNote()"
                  >Tambah</b-button>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                  <b-list-group>
                    <b-list-group-item
                        v-for="(input, k) in inputs"
                        :key="k"
                        class="d-flex justify-content-between align-items-center"
                        >{{ input.keterangan }}
                        <b-icon
                        v-if="!input.is_remove"
                        icon="trash"
                        scale="1"
                        variant="danger"
                        @click="delNote(k)"
                        ></b-icon>
                    </b-list-group-item>
                  </b-list-group>
              </b-col>
            </b-row>
            <b-button block pill class="mt-2" v-on:click="save"
            >Simpan Catatan</b-button
            >
        </form>
        </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import DoctorLOV from "./DoctorLOV.vue";
export default {
  name: "NoteModal",
  props: ['titleProps','numberProps', 'noteProps', 'typeProps'],
  components: {    
    DoctorLOV,
  },
  data(){
    return{
      inputs:[],
      newNote: null,
      options:[],
      tokenQue: null,
      orderNumber: null,
    }
  },
  beforeMount(){
    this.tokenQue = sessionStorage.getItem("token");
  },
  methods:{    
    reset(){
      console.log("RESET!")
      this.inputs = []
    },
    load(){
      console.log('isi note: ', this.noteProps)
      if(this.noteProps.length > 0){
        this.inputs = this.noteProps;
      }else{
        this.inputs = [];
      }
    },
    getResult(value) {
      this.selectedVal = value
      console.log("Selected Val", this.selectedVal)
      this.newNote = this.selectedVal.value
    },  
    addNote() {
      if (this.newNote == null || this.newNote == "") {
        this.$alert("Catatan tidak boleh kosong", 'Gagal', 'error');
        return;
      } else {
        this.inputs.push({
          keterangan: this.newNote,
        });
        this.newNote = null;
        this.$refs.CatatanModal.clearValue();
      }
    },
    delNote(index) {
      this.$confirm("Yakin hapus produk ini?", 'Konfirmasi', 'warning').then(() =>{
        this.inputs.splice(index, 1);
        this.$alert('Data telah terhapus', 'Berhasil', 'success')
      })
    },
    save: function () {
        this.orderNumber = this.numberProps
        var ket = [];
          for (let i = 0; i < this.inputs.length; i++) {
            ket.push(this.inputs[i].keterangan);
          }
          console.log("keterangan:", ket)
        this.$bvModal.hide('ModalCatatan')
        this.$emit('getNote', ket)
    },
  }

};
</script>

<style>
</style>