<template>
    <b-container class="mt-3 admin-page">
        <NavBar />
        <b-row>
            <b-col>
                <b-button
                href="#"
                variant="outline-secondary"
                class="btn-antrian antrian"
                block
                @click="handleQue"
                >
                Antrian Selanjutnya
                </b-button>
            </b-col>
            <b-col>
                <b-button disabled class="antrian-box mb-2">
                <h1>{{ queNum }}</h1>
                <p>Nomor Antrian Sekarang</p>
                </b-button>
                <b-button disabled class="antrian-box">
                <h1>{{ countQueNum }}</h1>
                <p>Jumlah Antrian Menunggu</p>
                </b-button>
            </b-col>
        </b-row>
        <form @submit.prevent='handleSubmit'>
            <b-row v-if="this.noteStatus > 0">
                <b-col>
                    <b-button
                        block
                        pill
                        class="mt-2"
                        @click.prevent="openModalCatatan"
                    >Tambah Catatan</b-button>
                </b-col>
            </b-row>
            <b-row v-if="this.submitStatus > 0">
                <b-col>
                    <b-button
                    block
                    pill
                    class="mt-2"
                    type="submit"
                    >Selesai</b-button>
                </b-col>
            </b-row>
        </form>
        <NoteModal 
        ref='ModalCatatan' 
        :titleProps='this.titleModal' 
        :numberProps='this.queNum'
        :typeProps=1
        @getNote='getNote' />
    </b-container>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from '../../services/UserServices';
import NoteModal from '@/components/NoteModal.vue';
import socket from '../../socket';
export default {
    name: 'VIP',
    components: {
        NavBar,
        NoteModal
    },
    data(){
        return {
            token: null,
            user: '',
            queNum: null,
            countQueNum: null,
            titleModal: '',
            noteArr: [],
            submitStatus: 0,
            noteStatus: 0,
            alertMessage: "Menyelesaikan nomor antrian",
        }
    },
    beforeMount(){
        this.token = sessionStorage.getItem('token');
        this.user = sessionStorage.getItem('user');
        if(this.token == null){
            this.$router.push('/login');
        }else if(this.user != 'VIP'){
            this.$router.push('/home');
        }else{
            this.getCountQueNum();
        }
    },
    mounted(){
        socket.disconnect();
        socket.auth ={
            token: this.token
        }
        socket.connect();

        socket.on('connect', () => {
            console.log('Handshake success vip');
        });
        socket.on('share push queue', (data) => {
            this.countQueNum = data.count;
        });
    },
    methods: {
        openModalCatatan(){
            console.log('nomor antrian: ', this.queNum);
            this.titleModal = 'Tambah Catatan';
            this.$bvModal.show('ModalCatatan');
        },
        getNote(data){
            if(data.length > 0){
                this.submitStatus = 1;
                console.log('isi data: ', data);
                this.noteArr = data;
            }else{
                this.submitStatus = 0;
            }
        },
        reload(){
            this.getCountQueNum();
            this.queNum = null;
            this.submitStatus= 0;
            this.noteStatus = 0;
            this.$refs.ModalCatatan.reset();
        },
        getCountQueNum(){
            const request = {
                token: this.token
            }
            callAPI(request, 'VIP GET COUNT').then((response) => {
                if(response.outError != 0){
                    this.$alert(response.outMessage, 'Gagal', 'error');
                }else{
                    this.countQueNum = response.outCount;
                }
            })
        },
        handleQue(){
            this.$confirm('Panggil antrian selanjutnya?').then(() => {
                const request = {
                    token: this.token
                }
                callAPI(request, 'VIP GET QUEUE NUMBER').then((response) => {
                    if(response.outError == 0){
                        console.log('res antrian: ', response);
                        this.queNum = response.outOrderNumber;
                        this.submitStatus = 0;
                        this.noteStatus = 1;
                    }else{
                        this.$alert(response.outMessage, 'Gagal', 'error');
                    }
                })
            })
        },
        handleSubmit(){
            this.$confirm('Selesai Transaksi? ').then(() =>{
                const request = {
                    token: this.token,
                    orderNumber: this.queNum,
                    actionCode: '20',
                    keterangan: this.noteArr
                }
                console.log('req push vip', request);
                callAPI(request, 'VIP PUSH QUEUE NUMBER').then((response) => {
                    if(response.outError == 0){
                        this.$alert(this.alertMessage, 'Berhasil', 'success');
                    }else{
                        this.$alert(this.alertMessage, 'Berhasil', 'success');
                    }
                    this.reload();
                })
            })
        }
    },
    destroyed(){
        socket.off('connect');
        socket.off('disconnect');
        socket.off('share push queue');
    }
}
</script>