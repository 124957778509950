<template>
  <b-row>
    <b-col>
      <center>
        <img
          src="../assets/images/logo.png"
          alt="Responsive image"
          class="benings-logo-utama"
        />
      </center>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "HeadLogo",
};
</script>

<style>
</style>