<template>
  <b-container class="mt-3 admin-page">
    <NavBar />
    <b-row>
      <b-col>
        <b-button
          href="#"
          variant="outline-secondary"
          class="btn-antrian antrian"
          block
          v-on:click="nextQueue"
          >Antrian Selanjutnya</b-button
        >
      </b-col>
      <b-col>
        <b-button disabled class="antrian-box mb-2">
          <p>Nomor Antrian Sekarang</p>
          <h1>{{ orderNumber }}</h1>
          <span>{{queCategory}}</span>
        </b-button>
        <b-button disabled class="antrian-box">
          <h1>{{ countQueueNumber }}</h1>
          <p>Jumlah Antrian Menunggu</p>
        </b-button>
      </b-col>
    </b-row>
      <b-button
      href="#"
      variant="outline-secondary"
      class="antrian-recall"
      block
      @click.prevent="handleRecall"
      v-if="this.orderNumber"
      >
      Panggil Ulang
      </b-button>
    <b-row class="mt-3">
      <b-col>
        <form @submit.prevent="handleSubmit">
          <b-row v-if="this.noteStatus > 0">
            <b-col>
              <b-button
                block
                pill
                class="mt-2"
                @click.prevent="openModalCatatan"
              >
              Tambah Catatan
              </b-button>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col v-if="this.submitStatus > 0">
              <b-button
                block
                pill
                class="mt-2"
                type="submit"
                >Lanjutkan Ke Kasir</b-button
              >
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
    <NoteModal 
    ref="ModalCatatan" 
    :titleProps="this.titleModal" 
    :numberProps="this.orderNumber" 
    :typeProps=2
    @getNote="getNote"/>
    <PhoneModal ref="ModalPhone" :titleProps="this.titleModal" :numberProps="this.orderNumber" @submitFinish="submitFinish"/>
  </b-container>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from "../../services/UserServices.js";
import socket from "../../socket";
import NoteModal from "@/components/NoteModal.vue";
import PhoneModal from '@/components/PhoneModal.vue';

export default {
  name: "DokterGigi",
  components: {
    NavBar,
    NoteModal,
    PhoneModal
  },

  data() {
    return {
      token: null,
      newNote: null,
      orderNumber: null,
      queCategory: null,
      countQueueNumber: null,
      noteArr: [],
      titleModal:"",
      noteStatus: 0,
      submitStatus: 0,
      alertMessage:"Melanjutkan nomor antrian",
    };
  },
  beforeMount() {
    this.token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");
    if (this.token == null) {
      return this.$router.push("/login");
    } else if (user != "DG") {
      return this.$router.push("/home");
    } else {
      this.status = 1;
      this.getTotQueNum();
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token};
    socket.connect();

    socket.on("connect", () => {
      console.log("Handshake success doctor")
    });

    socket.on("share push queue", (data) => {
      this.countQueueNumber = data.count
    });
  },
  methods: {
    openModalCatatan(){
      console.log("order number: ", this.orderNumber)
      this.titleModal = "Catatan";
      this.$bvModal.show("ModalCatatan");
    }, 
    getNote(data){
      if(data.length > 0){      
      this.submitStatus = 1;
      console.log("isi data: ", data);
      this.noteArr = data;
      }else{
        this.submitStatus = 0
      }
    },
    reload(){
      this.getTotQueNum();
      this.orderNumber = null;
      this.queCategory = null;
      this.submitStatus = 0;
      this.noteStatus = 0;
      this.$refs.ModalCatatan.reset();    
      this.$refs.ModalPhone.load();  
    },
    getTotQueNum: function () {
      const request = {
        token: this.token,
      };
      callAPI(request,'DENTIST GET COUNT QUEUE NUMBER').then((response) => {
        if (response.outError > 0) {
          this.$alert(response.outMessage);
        } else {
          this.countQueueNumber = response.outCount;
        }
      });
    },
    nextQueue: function () {
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = {
          token: this.token,
        };
        callAPI(request, 'DENTIST GET QUEUE NUMBER').then((response) => {
          if (response.outError > 0) {
            this.$alert(response.outMessage);
          } else {
            this.orderNumber = response.outOrderNumber;
            this.queCategory = response.outOrderCategory;
            this.noteStatus = 1;
            this.submitStatus = 0;            
          }
          this.getTotQueNum();
        });
      }).catch((err) => {
          return err
        });
    },
    handleRecall: function () {
      console.log("this.queNum : ", this.orderNumber)
      this.$confirm("Lanjutkan panggil ulang ?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token, orderNumber: this.orderNumber};
        callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
          console.log("response : ", response)
          if (response.outError == 0) {    
            this.$alert('Berhasil panggil ulang!', 'Berhasil', 'success')      
          } else {
            this.$alert('Gagal panggil ulang!', 'Gagal', 'error');
          }
          this.getTotQueNum();
        });
      });
    },    
    handleSubmit(){
      this.$confirm('Yakin melanjutkan? ', 'Konfirmasi', 'warning').then(() => {
        this.titleModal = "Submit Phone Number";
        this.$bvModal.show('ModalPhone');
      })
    },
    submitFinish(val){
      const request = {
        token: this.token,
        orderNumber: val.orderNumber,
        phoneNumber: val.phoneNumber,
        keterangan: val.note,
      }
      console.log('Request Push Dentist: ', request)
      callAPI(request, 'DENTIST PUSH QUEUE NUMBER').then((response) => {
        console.log('res submit: ',response.outMessage)
        if(response.outError == 0){
          this.$alert(this.alertMessage, 'Berhasil', 'success'); 
        }else{
          this.$alert(this.alertMessage, 'Gagal', 'error');
        }
        this.reload();
      })
    },
  },
};
</script>

<style>
</style>