<template>
  <div>
    <b-row>
      <b-img src="images/logo.png" center fluid class="benings-logo"></b-img>
    </b-row>
    <b-row>
      <b-col class="screen-box embed-responsive embed-responsive-16by9">
        <video width="320" height="240" :src="this.vidLists[this.currentPlay]" @ended="onEnd()" autoplay muted>
          Your browser does novideos/vid1.mp4 support the video tag.
        </video>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { callAPI } from "../services/UserServices"
export default {
  name: "Screens",
  data(){
    return{
      tokens:'',
      vidLists: [],
      videoLength: 0,
      currentPlay: 0
    }
  },
  beforeMount(){
    this.tokens = sessionStorage.getItem('token')
  },
  mounted() {
    const request = {
      token: this.tokens
    }
    callAPI(request, "GET DISPLAY ASSET").then((response) => {
      console.log("isi asset: ", response)
      for (let i=0 ; i < response.outData.length ; i++){
        this.vidLists.push(response.outData[i].asset_link)
      }
      this.videoLength = this.vidLists.length
      console.log("VidLists: ", this.vidLists)
      console.log("videoLength: ", this.videoLength)
      console.log("currentPlay: ", this.currentPlay)
    })
  },
  methods: {
    onEnd: function () {
      console.log('==================================')
      console.log('INI PLAY KE : ',this.currentPlay)
      console.log('==================================')
      console.log('==================================')
      console.log('THE END OF KUROHIGE')
      console.log('==================================')
      if (this.videoLength != this.currentPlay+1){
        this.currentPlay++
        console.log('==================================')
        console.log('NEXT VIDEO')
        console.log('==================================')
      }else{
        console.log('==================================')
        console.log('VIDEO TERAKHIR')
        console.log('==================================')
        this.currentPlay = 0
      }
    }
  }
};
</script>

<style>
</style>