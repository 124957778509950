<template>
  <div>
    <b-navbar style="padding: 0; margin: 15px 0px">
      <b-navbar-nav>
        <b-nav-item>
          <router-link to="/home">Home</router-link>
        </b-nav-item>

        <b-nav-item> 
          <router-link to="/pages/frontline" v-if="User == 'ADM'"
            >Frontline</router-link>
          <router-link to="/pages/dokter" v-if="User == 'DK'"
            >Dokter</router-link>
          <router-link to="/pages/kasir" v-if="User == 'CSR'"
            >Kasir</router-link>
          <router-link to="/pages/doktergigi" v-if="User == 'DG'"
            >Dokter Gigi</router-link>
          <router-link to="/pages/nursestation" v-if="User == 'NS'"
            >Nurse Station</router-link>          
        </b-nav-item>

        <b-nav-item>
          <router-link to='/pages/vip' v-if="User == 'VIP'">VIP</router-link>
          <router-link to='/pages/spesialist' v-if="User == 'SLS'">Produk Spesialist</router-link>
          <router-link to='/pages/gokasir' v-if="User == 'CSRGK'">Kasir Kurir</router-link>
        </b-nav-item>

        <b-nav-item-dropdown size="sm" text="Screen" v-if="User == 'DS'" variant="outline-secondary">
            <b-dropdown-item v-for="(screen, i) in screenList" :key="i">
              <span @click="toScreen(screen.screen_id)">{{screen.screen_code}}</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-if="User == 'DK'">
          <select v-model="statusSelect" @change="statusChange">
            <option value="0">Ready</option>
            <option value="1">Treatment</option>
            <option value="2">Away</option>
          </select>
        </b-nav-item>
        <b-nav-item-dropdown size="sm" :text='this.name' >
            <b-dropdown-item>
              <span>
                <a href="javascript:void(0)" @click="logout">Logout</a>
              </span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { callAPI } from '../services/UserServices';
var user;
export default {
  name: "NavBar",
  data() {
    return {
      // user: null
      token:null,
      screenList:[],
      statusSelect: 0,
      statusLabel: "",
      loginName:null,
    };
  },
  beforeMount(){
    this.token = sessionStorage.getItem('token');
    this.name = sessionStorage.getItem('loginName');
  },
  mounted() {
    const request = {
      token: this.token
    }
    callAPI(request, "GET SCREEN DISPLAY").then((response) => {
      console.log("Res: ", response)
      if(response.outError == 0){
        this.screenList = response.outData
        console.log("Isi screen list: ", this.screenList)
      }
    })
  },
  methods: {
    async logout() {
    const request = {
      token: this.token
    }
      callAPI(request, "USER LOGOUT").then((response) => {
        console.log("Res: ", response)
        if(response.outError == 0){          
          sessionStorage.removeItem("token"),
          sessionStorage.removeItem("user"),
          sessionStorage.removeItem("screen"),
          this.$router.push("/login");
        }else{
          this.$alert(response.outMessage)
        }
      })
    },
    toScreen(id){
      const request = { token: this.token, screenId: id};
        callAPI(request, 'ASSIGN USER TO SCREEN').then((response) => {
          if (response.outError == 0) {    
            console.log("response : ", response)
          } else {
            this.$alert(response.outMessage);
          }
        });
      sessionStorage.setItem('screen', id);
      this.$router.push('/screen')
    },
    statusChange(){
      switch(this.statusSelect){
        case '0':
          this.statusLabel = 'Ready'
          break;
        case '1':
          this.statusLabel = 'Treatment'
          break;
        case '2':
          this.statusLabel = 'Away'
          break;
      }
      console.log('status: ',this.statusSelect)
      const request = {
        token: this.token,
        status: this.statusSelect
      }
      callAPI(request, 'DOCTOR CHANGE STATUS').then((response) => {
        console.log('res doc status: ', response);
        if(response.outError == 0){
          this.$alert("Ubah status: "+ this.statusLabel, 'Berhasil', 'success');
        }else{
          this.$alert('Ubah status: '+ this.statusLabel, 'Gagal', 'warning');
        }
      })
    },
  },
  computed: {
    User: function () {
      user = sessionStorage.getItem("user");
      return user;
    },
  },
}
</script>

<style>
</style>