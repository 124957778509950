<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  function randimizeTime() {
    return String(Date.now());
  }

  function randomizeNumbers() {
    var text = String(Math.random());
    for (var i = text.length; i < 19; ++i) {
      text += '0';
    }
    return text.substring(2, 19);
  }

  var Generator = new function() {
    var counter = 0;
    var incrementCounter = function() {
      return String(++counter);
    };
    this.generateId = function() {
      // var value = String(Math.random());
      return incrementCounter() + '-' + randimizeTime() + '-' + randomizeNumbers();
    };
  };
  const BENINGS_ID = localStorage.getItem('BENINGS_ID');
  if (!BENINGS_ID){
    localStorage.setItem('BENINGS_ID', Generator.generateId());
  }
</script>