<template>
    <b-container class="mt-3 admin-page">
        <NavBar />
        <b-row>
            <b-col>
                <b-button
                :disabled="this.queBtnStatus > 0"
                href="#"
                variant="outline-secondary"
                class="btn-antrian antrian"
                block
                @click="handleQue"
                >
                Antrian Selanjutnya
                </b-button>
            </b-col>
            <b-col>
                <b-button disabled class="antrian-box mb-2">
                <p>Nomor Antrian Sekarang</p>
                <h1>{{ queNum }}</h1>
                <p>{{ queNumCat }}</p>
                </b-button>
                <b-button disabled class="antrian-box">
                <h1>{{ countQueNum }}</h1>
                <p>Jumlah Antrian Menunggu</p>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-button
                href="#"
                variant="outline-secondary"
                class="antrian-recall"
                block
                @click.prevent="handleRecall"
                v-if="this.queNum"
                >Panggil Ulang</b-button>
            </b-col>
        </b-row>
        <form @submit.prevent="handleSubmit">
            <b-row class="mt-3" v-if="this.skipMenu">
                <b-col>
                <v-select
                    :options="options"
                    :placeholder="placeholder"
                    v-model="checked"
                    value-field="item"
                    text-field="label"
                    @input="onChecked"
                    @click="skipQue"
                ></v-select>
                </b-col>
            </b-row>
            <b-row v-if="noteStatus > 0">
                <b-col>
                    <b-button
                        block
                        pill
                        class="mt-2"
                        @click.prevent="openModalCatatan"
                    >
                    Tambah Catatan
                    </b-button>
                </b-col>
            </b-row>
            <b-row v-if="submitStatus > 0">
                <b-col>
                    <b-button
                        block
                        pill
                        class="mt-2"
                        type="submit"
                    >Selesai Transaksi</b-button>
                </b-col>
            </b-row>
        </form>
        <NoteModal 
        ref="ModalCatatan" 
        :titleProps="this.titleModal" 
        :numberProps="this.queNum" 
        :typeProps=1
        @getNote="getNote"/>
        <PhoneModal 
        ref="ModalPhone" 
        :titleProps="this.titleModal" 
        :numberProps="this.queNum" 
        :codeProps="this.actionCode" 
        :noteProps="this.noteArr" 
        @submitFinish="submitFinish"/>
    </b-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from '../../services/UserServices';
import NoteModal from '@/components/NoteModal.vue';
import PhoneModal from '@/components/PhoneModal.vue';
import socket from '../../socket';
export default {
    name: 'GoKasir',
    components: {
        NavBar,
        NoteModal,
        PhoneModal
    },
    data(){
        return {
            token: null,
            user: "",
            queNum: null,
            queNumCat: null,
            countQueNum: null,
            actionCode: '',
            options: [],
            checked: null,
            placeholder: "",
            titleModal: "",
            noteArr: [],
            submitStatus: 0,
            noteStatus: 0,
            queBtnStatus: 0,
            alertMessage:"Menyelesaikan nomor antrian",
            skipMenu: true,
        }
    },
    beforeMount(){
        this.token = sessionStorage.getItem('token');
        this.user = sessionStorage.getItem('user');
        this.placeholder = 'Pilih antrian terlewat';
        if(this.token == null){
            this.$router.push('/login');
        }else if(this.user != "CSRGK"){
            this.$router.push('/home');
        }else{
            this.skipQue();
            this.getCountQueNum();
        }
    },
    mounted(){
        socket.disconnect();
        socket.auth = {
            token: this.token
        }
        socket.connect();

        socket.on('connect', () => {
            console.log('Handshake success cashier gojek')
        });
        socket.on('share push queue', (data) => {
            this.countQueNum = data.count
        })
    },
    methods: {
        openModalCatatan(){
            console.log('nomor antrian: ', this.queNum);
            this.titleModal = 'Tambah Catatan';
            this.$bvModal.show("ModalCatatan");
        },
        getNote(data){
            // if(data.length > 0){
                this.submitStatus = 1;
                console.log("isi data: ", data);
                this.noteArr = data;
            // }else{
            //     this.submitStatus = 0;
            // }
        },
        reload(){
            this.skipQue();
            this.getCountQueNum();
            this.queNum = null;
            this.queNumCat = null;
            this.placeholder = 'Pilih antrian terlewat';
            this.submitStatus = 0;
            this.noteStatus = 0;
            this.queBtnStatus = 0;
            this.checked = null;
            this.$refs.ModalPhone.load()
            this.$refs.ModalCatatan.reset()
        },
        skipQue(){
            const request = {
                token: this.token,
                searchValue: ''
            };
            callAPI(request, 'CASHIER GOJEK LOV CUSTOM QUEUE').then((response) => {
                let optionVal = []
                if(response.outData != null){
                    for(let x = 0; x < response.outData.length; x++){
                        let dataSkip = {
                            label: response.outData[x].order_number +' - '+response.outData[x].category_name,
                            value: response.outData[x]
                        }
                        optionVal.push(dataSkip)
                    }
                    this.options = optionVal
                    console.log('option: ', this.options)
                }
            });
        },
        onChecked(){
            console.log('nomor terlewat: ',this.checked)
            if(this.checked){              
                this.queNum = this.checked.value.order_number;
                this.queNumCat = this.checked.value.category_name;  
                this.queBtnStatus = 1;
                this.noteStatus = 1;
                this.submitStatus = 0;
            }else{                
                this.queBtnStatus = 0;
                this.noteStatus = 0;
                this.submitStatus = 0;
            }
        },
        getCountQueNum(){
            const request = {
                token: this.token
            }
            callAPI(request, 'CASHIER GOJEK GET COUNT QUEUE NUMBER').then((response) => {
                if(response.outError != 0){
                    console.log(response);
                }else{
                    this.countQueNum = response.outCount;
                }
            })
        },
        handleQue(){
            this.$confirm('Panggil antrian selanjutnya?', 'Konfirmasi', 'warning').then(() => {
                const request = {
                    token: this.token
                }
                callAPI(request, 'CASHIER GOJEK GET QUEUE NUMBER').then((response) => {
                    console.log('res antrian: ', response);
                    if(response.outError == 0){
                        this.queNum = response.outOrderNumber;                  
                        this.submitStatus = 0;
                        this.noteStatus = 1;
                    }else{
                        this.$alert('Belum ada antrian', 'Gagal', 'error');
                    }
                    this.skupQue();
                })
            })
        },
        handleRecall(){
            console.log('panggil ulang no: ', this.queNum);
            this.$confirm('Panggil Ulang?', 'Konfirmasi', 'warning').then(() => {
                const request = {
                    token: this.token,
                    orderNumber: this.queNum
                }
                callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
                    console.log('res recall: ', response);
                    if(response.outError == 0){
                        this.$alert('Panggil ulang berhasil', 'Berhasil', 'success');
                    }else{
                        this.$alert('Panggil ulang gagal', 'Gagal', 'error');
                    }
                    this.skipQue();
                })
            })
        },
        handleSubmit(){
            this.$confirm('Selesai Transaksi?', 'Konfirmasi', 'warning').then(() => {
                this.actionCode = '20'
                if(this.queNum == null){
                    this.queNum = this.checked.value.order_number
                }
                // this.phoneHandler(this.queNum, this.actionCode, this.noteArr,0);
                const dataSubmit = {
                    orderNumber: this.queNum,
                    actionCode: this.actionCode,
                    phoneNumber: 0,
                    note: this.noteArr                   
                }
                this.submitFinish(dataSubmit)
            })
        },
        // phoneHandler(...data){
        //     console.log('isi dari data phone: ', data);
        //     this.titleModal = 'Submit Phone Number';
        //     this.queNum = data[0];
        //     this.actionCode = data[1];
        //     this.noteArr = data[2];
        //     this.$bvModal.show("ModalPhone");
        // },
        submitFinish(val){
            const request = {
                token: this.token,
                orderNumber: val.orderNumber,
                actionCode: val.actionCode,
                phoneNumber: val.phoneNumber,
                keterangan: val.note,
            }
            console.log('req push cashier gojek: ', request);
            callAPI(request, 'CASHIER GOJEK PUSH QUEUE NUMBER').then((response) => {
                if(response.outError == 0){
                    this.$alert(this.alertMessage, 'Berhasil', 'success');
                }else{
                    this.$alert(this.alertMessage, 'Gagal', 'error');
                }
                this.reload();
            })
        }
    },    
    destroyed() { 
        socket.off("connect");
        socket.off("disconnect");
        socket.off("share push queue");
    },
}
</script>