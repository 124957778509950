import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vSelect from 'vue-select';
import VueSimpleAlert from "vue-simple-alert";



import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
// import VueToast from 'vue-toast-notification'
// import 'vue-toast-notification/dist/theme-default.css'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/main.css';

import 'vue-select/dist/vue-select.css';

const APP_LEVEL = process.env.VUE_APP_LEVEL

if (APP_LEVEL == "production") {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function() {};
    }
}

// Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('v-select', vSelect)
Vue.use(VueSimpleAlert)
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')