<template>
  <b-container class="admin-page">
    <!-- <NavBar /> -->
    <HeadlLogo />
    <b-row>
      <b-col>
        <b-form @submit.prevent="submit">
          <h3>Login Form</h3>
          <hr />
          <b-form-group id="username" label-for="username">
            <b-form-input
              id="username"
              v-model="username"
              type="text"
              placeholder="Username"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="password" label-for="password">
            <b-form-input
              id="password"
              v-model="password"
              type="password"
              placeholder="Password"
              required
            ></b-form-input>
          </b-form-group>
          <p style="color:red;font-size:15px">{{message}}</p>
          <b-button type="submit" class="float-right">Login</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HeadlLogo from "@/components/HeadLogo.vue";
import { callAPI } from "../services/UserServices.js";

export default {
  name: "Login",
  components: {
    HeadlLogo,
  },
  data() {
    return {
      username: "",
      password: "",
      message:""
    };
  },
  methods: {
    submit() {
      const BENINGS_ID = localStorage.getItem('BENINGS_ID');
      const request = {
        userName: this.username,
        userPassword: this.password,
        beningsId:BENINGS_ID
      };
      callAPI(request, 'USER LOGIN').then((response) => {
        if (response.outError > 0){
          this.message = "*"+response.outMessage;
          return
        }else{
          const name = response.outUserAlias.toLowerCase();
          const loginName = name.substring(0, 1).toUpperCase() + name.substring(1);
          sessionStorage.setItem("token", response.outToken);
          sessionStorage.setItem("loginName", loginName);
          sessionStorage.setItem("user", response.outUserTypeCode);
          sessionStorage.setItem("special", response.outUsedProductSpesialist);
          if (response.outUserTypeCode == "OPS"){
            this.$router.push("/antrian")
          }else{
            this.$router.push("/home")
          }
        }
      });
    },
  },
};
</script>

<style>
</style>