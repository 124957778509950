<template>
  <b-container class="mt-3 admin-page">
    <NavBar />
    <b-row>
      <b-col>
        <b-button
          href="#"
          variant="outline-secondary"
          class="btn-antrian antrian"
          block
          v-on:click="nextQueue"
          >Antrian Selanjutnya</b-button
        >
      </b-col>
      <b-col>
        <b-button disabled class="antrian-box mb-2">
          <p>Nomor Antrian Sekarang</p>
          <h1>{{ orderNumber }}</h1>
          <span>{{queCategory}}</span>
        </b-button>
        <b-button disabled class="antrian-box">
          <h1>{{ countQueueNumber }}</h1>
          <p>Jumlah Antrian Menunggu</p>
        </b-button>
      </b-col>
    </b-row>
      <b-button
      href="#"
      variant="outline-secondary"
      class="antrian-recall"
      block
      @click.prevent="handleRecall"
      v-if="this.orderNumber"
      >
      Panggil Ulang
      </b-button>
    <b-row class="mt-3">
      <b-col>
        <form @submit.prevent="handleSubmit">                
          <b-row v-if="this.noteStatus > 0">
            <b-col>
              <b-button
                block
                pill
                class="mt-2"
                @click.prevent="openModalCatatan"
              >
              Tambah Catatan
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="this.submitStatus > 0">
              <b-button
                block
                pill
                class="mt-2"
                type="submit"
                >{{ submitBtn }}</b-button
              >
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
    <NoteModal 
    ref="ModalCatatan" 
    :titleProps="this.titleModal" 
    :numberProps="this.orderNumber" 
    :noteProps="this.attrModal" 
    :typeProps=1
    @getNote="getNote"/>
  </b-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {callAPI} from "../../services/UserServices.js";
import NoteModal from "@/components/NoteModal.vue";
import socket from "../../socket";

export default {
  name: "Dokter",
  components: {
    NavBar,
    NoteModal
  },

  data() {
    return {
      token: null,
      user: null,
      special: null,
      orderNumber: null,
      queCategory: null,
      queCode: null,
      countQueueNumber: null,
      placeholder: null,
      noteArr: [],
      attrModal:[],
      titleModal:"",
      noteStatus: 0,
      submitStatus: 0,
      alertMessage:"Melanjutkan nomor antrian",
      submitBtn: ""
    };
  },

  beforeMount() {
    this.token = sessionStorage.getItem("token");
    this.user = sessionStorage.getItem("user");
    this.special = sessionStorage.getItem("special");
    if (this.token == null) {
      return this.$router.push("/login");
    } else if (this.user != "DK") {
      return this.$router.push("/home");
    } else {
      this.getTotQueNum();
    }
  },
  mounted() {
    socket.disconnect();
    socket.auth = { token: this.token};
    socket.connect();

    socket.on("connect", () => {
      console.log("Handshake success doctor")
    });

    socket.on("share push queue", (data) => {
      this.countQueueNumber = data.count
    });
  },
  methods: {
    openModalCatatan(){
      console.log("order number: ", this.orderNumber)
      this.titleModal = "Catatan";
      this.$bvModal.show("ModalCatatan");
      if(this.noteArr.length > 0){
        for(let x = 0;x < this.noteArr.length;x++){
          this.attrModal.push({
              keterangan: this.noteArr[x],
              is_remove: 0
          })
        }
      }
      
      this.$refs.ModalCatatan.load();
    }, 
    getNote(data){
      if(data.length > 0){      
      this.submitStatus = 1;
      console.log("isi data: ", data);
      this.noteArr = data;
      this.attrModal = []
        
      }else{
        this.noteArr = data;
        this.submitStatus = 1
        this.attrModal = []
      }
    },
    reload(){
      this.getTotQueNum();
      this.orderNumber = null;
      this.queCategory = null;
      this.submitStatus = 0;
      this.noteStatus = 0;
      this.attrModal = [];
      this.noteArr = [];
      this.attrModal = [];
      this.$refs.ModalCatatan.reset();  
    },
    getTotQueNum: function () {
      const request = {
        token: this.token,
      };
      callAPI(request, 'DOCTOR GET COUNT QUEUE NUMBER').then((response) => {
        if (response.outError > 0) {
          this.$alert(response.outMessage);
        } else {
          this.countQueueNumber = response.outCount;
        }
      });
    },
    nextQueue: function () {
      this.$confirm("Panggil antrian selanjutnya?", 'Konfirmasi', 'warning').then(() => {
        const request = {
          token: this.token,
        };
        callAPI(request, 'DOCTOR GET QUEUE NUMBER').then((response) => {
            console.log('res doctor: ', response)
          if (response.outError > 0) {
            this.$alert('Belum ada antrian', 'Gagal', 'error');
            this.noteStatus = 0;
            this.submitStatus = 0;
          } else {
            this.orderNumber = response.outOrderNumber;
            this.queCategory = response.outCategoryName;
            this.queCode = response.outCategoryCode;
            this.noteStatus = 1;
            this.submitStatus = 0;
            if(this.queCode == 'CO05'){
              this.getAttribute(this.orderNumber)
            }
            if(this.special == 'true'){
              this.submitBtn = "Lanjutkan ke Prod. Spesialist"
            }else{              
              this.submitBtn = "Lanjutkan ke Kasir"
            }
          }
          this.getTotQueNum();
        });
      }).catch((err) => {
          return err
        });
    },
    async getAttribute(val){
        const request = {
            token: this.token,
            orderNumber: val
        }
        console.log('req getAttr: ', request)
        await callAPI(request, 'DOCTOR GET ATTRIBUTE').then((response) => {
            console.log('res attr: ', response)
            if(response.outError == 0){
                console.log('data attr: ', response.outData);
                for(let x = 0;x < response.outData.length;x++){
                    this.attrModal.push({
                        keterangan: response.outData[x].attribute,
                        is_remove: 1
                        })
                }
            }else{
                console.log(response.outMessage);
            }
        })
        console.log('isi catatan sebelumnya: ',this.attrModal)
    },
    handleRecall: function () {
      console.log("this.queNum : ", this.orderNumber)
      this.$confirm("Lanjutkan panggil ulang ?", 'Konfirmasi', 'warning').then(() => {
        const request = { token: this.token, orderNumber: this.orderNumber};
        callAPI(request, 'PUSH RECALL QUEUE').then((response) => {
          console.log("response1 : ", response)
          if (response.outError == 0) {    
            this.$alert('Berhasil memanggil ulang', 'Berhasil', 'success');
            console.log("response : ", response)        
          } else {
            this.$alert('Tidak ada nomor', 'Gagal', 'error');
          }
          this.getTotQueNum();
        });
      });
    },
    handleSubmit(){
      this.$confirm('Lanjutkan transaksi?', 'Konfirmasi', 'warning').then(() => {        
        const request = {        
          orderNumber: this.orderNumber,
          token: this.token,
          keterangan: this.noteArr,
        }
        console.log('req submit: ', request)
        callAPI(request, 'DOCTOR PUSH QUEUE NUMBER').then((response) => {
          console.log('res push: ', response)
          if(response.outError == 0){
            this.$alert(this.alertMessage, 'Berhasil', 'success');
          }else{
            this.$alert(this.alertMessage, 'Gagal', 'error');
          }
          this.reload();          
        })
      }).catch(() =>{
        return;
      });
    }
  },
};
</script>

<style>
</style>