import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Screen from '../views/Screen.vue';
import Antrian from '../views/Antrian.vue';
import Login from '../views/Login.vue';
import Logins from '../views/Login.vue';
import Register from '../views/Register.vue';
import Kasir from '../views/pages/Kasir.vue';
import Frontline from '../views/pages/Frontline.vue';
import Dokter from '../views/pages/Dokter.vue';
import DokterGigi from '../views/pages/DokterGigi.vue';
import VIP from '../views/pages/VIP.vue';
import GoKasir from '../views/pages/GoKasir.vue';
import ProdSpesialist from '../views/pages/ProdSpesialist.vue';
import NurseStation from '../views/pages/NurseStation.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/login',
        name: 'Logins',
        component: Logins
    },
    {
        path: '/screen',
        name: 'Screen',
        component: Screen
    },
    {
        path: '/antrian',
        name: 'Antrian',
        component: Antrian
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/pages/frontline',
        name: 'Frontline',
        component: Frontline
    },
    {
        path: '/pages/kasir',
        name: 'Kasir',
        component: Kasir
    },
    {
        path: '/pages/dokter',
        name: 'Dokter',
        component: Dokter
    },
    {
        path: '/pages/doktergigi',
        name: 'DokterGigi',
        component: DokterGigi
    },
    {
        path: '/pages/vip',
        name: 'VIP',
        component: VIP
    },
    {
        path: '/pages/spesialist',
        name: 'ProdSpesialist',
        component: ProdSpesialist
    },
    {
        path: '/pages/gokasir',
        name: 'GoKasir',
        component: GoKasir
    },
    {
        path: '/pages/nursestation',
        name: 'NurseStation',
        component: NurseStation
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;