<template>
  <b-container class="admin-page">
    <NavBar />
    <HeadlLogo />
    <b-row>
      <b-col>
        <b-form @submit.prevent="submit">
          <h3>Register Form</h3>
          <hr />
          <b-form-group id="username" label-for="username">
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              placeholder="Username"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="name" label-for="name">
            <b-form-input
              id="name"
              v-model="form.displayname"
              type="text"
              placeholder="Display name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="password" label-for="password">
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              placeholder="Password"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" class="float-right">Register</b-button>
          <b-button to="/" variant="outline">Back</b-button>
        </b-form>
        <p v-if="showError" id="error">Username already exists</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HeadlLogo from "@/components/HeadLogo.vue"
import NavBar from "@/components/NavBar.vue"
import {mapActions} from 'vuex'
export default {
  name: "Register",
  components: {
    HeadlLogo,
    NavBar
  },
  data(){
    return{
      form: {
        username: '',
        displayname: '',
        password: ''
      },
      showError: false
    }
  },
  methods: {
    ...mapActions(['Register']),
    async submit(){
      try{
        await this.Register(this.form)
        this.$router.push('/login')
        this.showError = false
      }
      catch(error){
        this.showError = true
      }
    }
  }
};
</script>

<style>
</style>