let url = process.env.VUE_APP_DEVEL_HOST
export async function callAPI(data, prcCode) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'dbcode': 'benings_antrian_devel',
            'procedure-code': prcCode,
            'Authorization': 'Basic ZGV2bEFQSToxMjM0NTY3ODkw'
        },
        body: JSON.stringify(data)
    })
    return await response.json();
}