<template>
  <div class="main-antrian">
    <b-container>
      <HeadLogo />
      <div v-for="(i, index) in columnPerRow" :key="i">
        <b-row :cols="columnPerRow[index]">
          <b-col class="mb-3" v-for="item in columnPerRow[index]" :key="item">
            <b-button
              href="#"
              class="btn-antrian"
              block
              @click.native="
                print(
                  categories[index * 3 + (item - 1)].category_name,
                  tokenQueue,
                  categories[index * 3 + (item - 1)].category_code
                )
              "
            >
              {{ categories[index * 3 + (item - 1)].category_name }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import HeadLogo from "@/components/HeadLogo.vue";
import { callAPI } from "../services/UserServices.js";

export default {
  name: "Antrian",
  components: {
    HeadLogo,
  },
  data() {
    return {
      tokenQueue: "",
      count: 0,
      fixedRow: 0,
      row: 0,
      columnPerRow: [],
      categories: null,
      counterIndex: 0,
      datePrint: '',
    };
  },
  beforeMount() {
    this.tokenQueue = sessionStorage.getItem("token");
    const tokenQue = { token: this.tokenQueue };

    callAPI(tokenQue, 'GET CATEGORY').then((response) => {
      this.categories = response.outData;
      this.count = this.categories.length;
      this.fixedRow = this.count / 3;
      this.row = Math.ceil(Number(this.fixedRow));
      for (let i = 0; i < this.row; i++) {
        if (this.count > 3) {
          this.columnPerRow.push(3);
          this.count = this.count - 3;
        } else {
          this.columnPerRow.push(this.count);
        }
      }
    });
  },
  created() {
    setInterval(this.getNow, 1000)
  },
  methods: {    
    getNow() {
        const today = new Date();
        const date = (today.getDate()<10?'0':'')+today.getDate()+'-'+((today.getMonth()+1)<10?'0':'')+(today.getMonth()+1)+'-'+today.getFullYear();
        const time = (today.getHours()<10?'0':'')+today.getHours() + ":" + (today.getMinutes()<10?'0':'') + today.getMinutes() +":" +(today.getSeconds()<10?'0':'')+today.getSeconds();
        const dateTime = date +' '+ time;
        this.datePrint = dateTime;
    },
    print(data, param_token, categoryCode) {
      const request = {
        token: param_token,
        categoryCode: categoryCode,
      };
      callAPI(request, 'GET QUEUE NUMBER').then((response) => {
        const siteName = response.outSiteName;
        const number = response.outOrderNumber;
        const dateNow = this.datePrint;
        const WinPrint = window.open();
        WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            body{
              margin: 0px auto 0px auto;
            }
            center{     
              border: 1px dotted grey;
              h1, h3, p{
                margin: 0;
                padding: 0;
              }
              h1{
                font-weight: bold;
              }
              p{
                font-size: 15px;
              }
            }
          </style>
        </head>
        <body>         
          <center>
          <h3>Benings Clinic</h3>
            <h3>No. Antrian</h3> 
            <h1>${number}</h1>
            <p>${data}</p>
            <h3>${siteName}</h3>
            <small>${dateNow}</small>
            <small>=========================</small>
            <small>*mohon selalu dibawa antrian ini selama di dalam klinik</small>
          </center>
        </body>
      </html>`);
      self.focus();
        // WinPrint.focus();
        WinPrint.onfocus = function () {
          WinPrint.document.close();
          
        WinPrint.print();
        // WinPrint.print();
          WinPrint.close();
        };
      });
    },
  },
};
</script>
