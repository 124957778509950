<template>
  <b-row>
    <b-col>
      <b-modal id="ModalAntrianDokter" size="md" centered hide-footer :title="titleProps">        
        <p v-if="modalTypeProps == 2">Antrian No: {{numberProps}}</p>
        <b-card class="mb-3">
          <b-row>
            <b-col>
              <b-form-radio-group
                v-model="selected"
                name="radio-dokter"
                id="radio-dokter"
              >
                <b-row>
                  <b-col align-self="center" cols="12" v-for="(data, id) in doctorData" :key="id">                    
                    <b-form-radio
                      :disabled="!data.is_active"
                      :value="data.doctor_id"
                      @change="loadData(data.doctor_id)"
                      >{{data.doctor_name}} 
                      <span class="doc-que-online" v-if="data.is_active && data.doctor_status == 0" title="Ready">{{data.count_queue}}</span>
                      <span class="doc-que-treatment" v-if="data.is_active && data.doctor_status == 1" title="On Treatment">{{data.count_queue}}</span>
                      <span class="doc-que-away" v-if="data.is_active && data.doctor_status == 2" title="Away">{{data.count_queue}}</span>
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-form-radio-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row v-if="modalTypeProps == 2">
          <b-col>
            <b-button pill block @click="next" :disabled="selected < 1">Lanjutkan</b-button>
          </b-col>
        </b-row>        
        <div class="card p-1" v-if="modalTypeProps == 1">     
          <div class="row">
            <div class="col">
              <b-table 
                striped 
                hover 
                :items="items"
                :fields="fields"
                >
                  <template #cell(Aksi)='data'>
                    <a href="#" class="text-danger" @click="remove(data)">Hapus</a>
                  </template>
                </b-table>
            </div>
          </div>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {callAPI} from "../services/UserServices.js";
export default {
  name: "AntrianDokterModal",
  props: ['titleProps','numberProps','modalTypeProps', 'codeProps', 'numberCodeProps'],
  components: { },
  data(){
    return{       
      token: null,
      doctorData: [],
      items: [],
      selected: null,
      fields:[
        {
          key: 'order_number',
          label: 'No. Antrian'
        },
        {
          key: 'registration_time',
          label: 'Waktu Registrasi'
        },
          'Aksi'
      ],

    }
  },
  methods:{ 
    load(){      
      this.selected = null
      this.items = []
      this.token = sessionStorage.getItem("token");
      console.log("Nomor: ", this.numberProps)
      const request = {
        token: this.token
      }
      callAPI(request,'GET DOCTOR IDLE').then((response) => {
        if(response.outError == 0){          
          console.log('Response dokter: ', response.outData)
          this.doctorData = response.outData
        }

      })
    },
    next(){
      const dataToDoctor = {
        queNum: this.numberProps,
        queCode: this.numberCodeProps,
        actionCode: this.codeProps,
        doctorId: this.selected
      }
      this.$emit('toDoctor', dataToDoctor);
      this.$bvModal.hide("ModalAntrianDokter");
    },
    loadData(id){
      const request = {
        token: this.token,
        doctorId: id
      }
      callAPI(request, 'GET WAITING LIST DOCTOR').then((response) => {
        console.log("res tabel dokter: ", response)
        if(response.outError == 0){
          this.items = response.outData
        }else{
          console.log("Error: ", response.outMessage)
          this.items = []
        }
      })
    },
    remove(data){
      this.$confirm('Yakin ingin menghapus data?', 'Konfirmasi', 'warning').then(() => {
        console.log("Terhapus!!", data.item.order_number)
        const number = data.item.order_number
        const request = {
          token: this.token,
          orderNumber: number
        }
        callAPI(request, 'REMOVE DOCTOR WAITING LIST').then((response) => {
          console.log("Hasil hapus: ", response)
          if(response.outError == 0){
            this.$alert('Berhasil hapus data', 'Berhasil', 'success');
            this.load()
          }
        })
      })
    }
  }

};
</script>

<style>
</style>